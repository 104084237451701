import React from 'react';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { Select } from '../Select';
import trashImage from '../../../assets/trash.svg';
import { TinPair, UseTinPairsHandlers } from './UseTinPairsHook';

export interface Choice {
  value: string | number | null;
  title: string;
}

interface TinRowProps {
  index: number;
  pair: TinPair;
  formatter: DriverRegistrationPortalNoAuthService.TaxIdentificationNumberFormat | null;
  handlers: UseTinPairsHandlers;
  translations: DriverRegistrationPortalNoAuthService.CommonTranslations;
  choices: Choice[];
  fieldError: string | undefined;
  showDeleteButton: boolean;
  isLast: boolean;
}

export const TinRow: React.FC<TinRowProps> = ({
  index,
  pair,
  formatter,
  handlers,
  translations,
  choices,
  fieldError,
  showDeleteButton,
  isLast,
}) => {
  const {
    setTin,
    setCountry,
    saveIfEnabledAfterValidation,
    deleteTinRowAndSaveIfEnabled,
  } = handlers;

  const inputBorderWithErrorClassName = 'border-red-500';
  const inputBorderClassName = 'border-gray-200 focus:border-green-500';

  const bgClassName = fieldError ? 'bg-white' : 'bg-gray-200';

  const inputPlaceholder = formatter
    ? formatter.placeholder
    : translations.tax_identification_numbers.number_placeholder;

  const inputBorderClass =
    pair.tinError || fieldError
      ? inputBorderWithErrorClassName
      : inputBorderClassName;
  const separatorBorder = isLast ? '' : 'border-b border-gray-300 pb-1 mb-3';

  return (
    <React.Fragment key={pair.index}>
      <div className="flex w-full">
        <input
          className={`outline-none h-12 p-3 border-2 placeholder-gray-700 rounded focus:bg-white flex-grow ${inputBorderClass} ${bgClassName}`}
          type="text"
          placeholder={inputPlaceholder}
          defaultValue={pair.tin ?? ''}
          onChange={(e) => {
            setTin(index, (e.target as HTMLInputElement).value);
          }}
          onBlur={() => {
            saveIfEnabledAfterValidation(index);
          }}
        />
      </div>
      {pair.tinError && (
        <div className="w-full">
          <label className="text-red-700 text-sm text-left rtl:text-right break-words">
            {pair.tinError}
          </label>
        </div>
      )}
      <div className={`flex w-full ${separatorBorder}`}>
        <div className="flex-grow">
          <Select
            updateHandler={(val) => {
              setCountry(index, val ? (val as string) : undefined);
            }}
            blurHandler={() => {
              saveIfEnabledAfterValidation(index);
            }}
            choices={choices}
            title=""
            value={pair.country ?? null}
            isRequired={false}
            error={pair.countryError || (fieldError ? ' ' : undefined)}
            placeholder={
              translations.tax_identification_numbers.country_placeholder
            }
          />
        </div>
        {showDeleteButton && (
          <button
            type="button"
            onClick={() => deleteTinRowAndSaveIfEnabled(index)}
          >
            <div className="p-2.5 ml-2 bg-gray-200 rounded-lg w-12 h-12">
              <img
                src={trashImage}
                alt="delete"
                className="w-full h-full p-1"
              />
            </div>
          </button>
        )}
      </div>
    </React.Fragment>
  );
};
