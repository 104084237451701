import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import DocumentResultStatus = DriverRegistrationPortalNoAuthService.DocumentResultStatus;
import { ExtendedField } from '../types';
import { getWebMarketingData } from '../../common/helpers';

enum SortPriority {
  UNSORTED = 0,
  DECLINED = 1,
  NOT_UPLOADED_MANDATORY = 2,
  NOT_UPLOADED_OPTIONAL = 3,
  IN_REVIEW = 4,
  CONFIRMED = 5,
}

export function isDocumentUploadField(
  f: DriverRegistrationPortalNoAuthService.Field
): boolean {
  return (
    DriverRegistrationPortalNoAuthService.FrontendFieldDefinitionType
      .DOCUMENT_UPLOAD === f.type
  );
}

export function calculateOrdinalForDocument(
  field: ExtendedField
): SortPriority {
  if (!isDocumentUploadField(field)) {
    return SortPriority.UNSORTED;
  }

  switch (field.document_status) {
    case DocumentResultStatus.DECLINED:
      return SortPriority.DECLINED;

    case DocumentResultStatus.IN_REVIEW:
      return SortPriority.IN_REVIEW;

    case DocumentResultStatus.CONFIRMED:
      return SortPriority.CONFIRMED;

    case DocumentResultStatus.NOT_UPLOADED:
    default: {
      if (field.is_required) {
        return SortPriority.NOT_UPLOADED_MANDATORY;
      }
      return SortPriority.NOT_UPLOADED_OPTIONAL;
    }
  }
}

export function buildPostFormPayload(
  step: DriverRegistrationPortalNoAuthService.Step,
  currentStep: DriverRegistrationPortalNoAuthService.DriverRegistrationLogLastStep
): DriverRegistrationPortalNoAuthService.SignupRequest {
  const payload: any = {
    signup_data: {
      last_step: currentStep,
    },
  };

  const genericFields: { key: string; value: string }[] = [];
  step.fields.forEach((field) => {
    if (
      field.current_value !== null &&
      field.type !==
        DriverRegistrationPortalNoAuthService.FrontendFieldDefinitionType.HIDDEN
    ) {
      if (field.generic_field_key && field.current_value !== undefined) {
        genericFields.push({
          key: field.generic_field_key,
          value: field.current_value,
        });
      } else {
        payload.signup_data[field.name] = field.current_value;
      }
    }
  });
  if (genericFields.length) {
    payload.signup_data.generic_fields = genericFields;
  }

  payload.web_marketing_data = getWebMarketingData();

  return payload;
}
