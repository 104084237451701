import * as React from 'react';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { useSelector } from '../redux/store';
import icon from '../assets/check-mark.svg';
import { Button } from './Button';
import { getMixpanel } from '../redux/mixpanel/mixpanelSelectors';
import { MixpanelUiEvents } from '../common/events';
import {
  AppType,
  getAppTypeFromAppVersion,
  getAppVersion,
} from '../common/helpers';
import { getHasPartnerAccount } from '../redux/auth/authSelectors';
import * as LocalStorage from '../common/LocalStorageService';
import { DeviceData } from '../common/deviceData';
import {
  getCurrentStepName,
  getHash,
  getTranslations,
} from '../redux/form/formSelectors';
import { getLinkToAppStore } from '../common/environment';

export const ContinueInAppBanner: React.FC = () => {
  const mixpanel = useSelector(getMixpanel);
  const hasPartnerAccount = useSelector(getHasPartnerAccount);
  const regHash = useSelector(getHash);
  const regHashId = (regHash || '').substring(0, 4) || 'hash';
  const formStep = useSelector(getCurrentStepName);
  const commonTranslations = useSelector(getTranslations);

  const shouldRender = React.useMemo(() => {
    if (!getLinkToAppStore()) {
      return false;
    }

    let isInDriverApp = false;
    const appVersion = getAppVersion();
    if (appVersion) {
      const appType = getAppTypeFromAppVersion(appVersion);
      if (appType === AppType.ANDROID || appType === AppType.IOS) {
        isInDriverApp = true;
      }
    }
    const wasDisplayedBefore =
      LocalStorage.getItem(LocalStorage.ContinueInAppDisplayed) === regHashId;

    const isSecondStep =
      formStep ===
      DriverRegistrationPortalNoAuthService.DriverRegistrationLogLastStep.STEP2;

    return (
      isSecondStep && hasPartnerAccount && !isInDriverApp && !wasDisplayedBefore
    );
  }, [hasPartnerAccount, regHashId, formStep]);

  const shouldDisplayButton = React.useMemo(() => {
    // It makes sense to display link to App Store on mobile devices only.
    // And we need to be sure we are not inside app (driver or rider) because inside app link to store does not work.
    const appVersion = getAppVersion();
    const isInBrowser = !appVersion;
    return isInBrowser && DeviceData.isMobileBrowser();
  }, []);

  React.useEffect(() => {
    if (mixpanel && shouldRender && shouldDisplayButton) {
      mixpanel.track(MixpanelUiEvents.CONTINUE_IN_APP_DISPLAYED);
      LocalStorage.setItem(LocalStorage.ContinueInAppDisplayed, regHashId);
    }
  }, [mixpanel, shouldRender, shouldDisplayButton, regHashId]);

  const handleClick = React.useCallback(async () => {
    await mixpanel?.track(MixpanelUiEvents.CONTINUE_IN_APP_CLICKED);
    window.open(getLinkToAppStore(), '_blank');
  }, [mixpanel]);

  if (!shouldRender) {
    return null;
  }

  return (
    <div className="flex flex-col content-center justify-center pt-0 pb-8 mx-auto">
      <div className="p-4 rounded-lg border border-gray-400">
        <div className="text-left rtl:text-right mx-auto">
          <div className="flex flex-row">
            <div className="shrink pr-2 pt-0.5">
              <img
                src={icon}
                alt="Info"
                width={24}
                height={24}
                color="#2F313F"
              />
            </div>
            <div className="w-full">
              <div className="text-md font-bold">
                {commonTranslations.app_promotion.your_account_created}
              </div>
              <div className="text-gray-700 text-sm">
                <p className="mt-2">
                  {
                    commonTranslations.app_promotion
                      .continue_registration_in_app
                  }
                </p>
              </div>
              {shouldDisplayButton && (
                <div className="text-right">
                  <Button
                    onClick={handleClick}
                    color="bg-green-500"
                    loadingTextColor="text-green-500"
                    label={commonTranslations.app_promotion.get_driver_app}
                    disabled={false}
                    loading={false}
                    id="continue-in-app"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
