import React from 'react';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { BaseInputProps } from './BaseInput';
import ErrorHint from '../common/ErrorHint';
import FieldDescription from '../common/FieldDescription';
import { useSelector } from '../../redux/store';
import { getFieldError } from '../../redux/notification/notificationsSelectors';

import {
  TinPair,
  useTinPairs,
} from './tax_identification_numbers/UseTinPairsHook';
import { Choice, TinRow } from './tax_identification_numbers/TinRow';
import {
  getCountryCode,
  getTranslations,
} from '../../redux/form/formSelectors';

interface TinProps
  extends BaseInputProps<{ country?: string; tin?: string }[]> {
  choices: Choice[];
  field: DriverRegistrationPortalNoAuthService.Field;
}

export const TaxIdentificationNumberField: React.FC<TinProps> = ({
  choices,
  description,
  field,
  updateHandler: updateCallback,
  blurHandler: blurCallback,
  title,
}: TinProps) => {
  const error = useSelector(getFieldError(field.name));
  const countryCode = useSelector(getCountryCode);
  const commonTranslations = useSelector(getTranslations);

  const { tinPairs, tinFormatters, handlers } = useTinPairs(
    field.current_value as TinPair[],
    updateCallback,
    blurCallback,
    commonTranslations,
    countryCode
  );

  const { addNewTinRow } = handlers;

  const isMoreThanOneTinPair = tinPairs.length > 1;
  const canAddMoreTinPairs = tinPairs.length < 5;

  return (
    <div className="w-full">
      <label
        htmlFor={title}
        className={`text-black font-bold text-sm text-left rtl:text-right ${
          field.is_required ? "after:text-red-700 after:content-['_*']" : ''
        }`}
      >
        {title}
      </label>
      <FieldDescription description={description} />
      <div className="flex flex-col w-full place-items-start">
        {tinPairs.map((pair, index) => {
          const tinFormatter = pair.country
            ? tinFormatters[pair.country]
            : null;
          const isLast = index >= tinPairs.length - 1;
          return (
            <TinRow
              key={pair.index}
              index={index}
              pair={pair}
              formatter={tinFormatter}
              handlers={handlers}
              translations={commonTranslations}
              choices={choices}
              fieldError={error}
              showDeleteButton={isMoreThanOneTinPair}
              isLast={isLast}
            />
          );
        })}
        {canAddMoreTinPairs && (
          <button type="button" onClick={addNewTinRow}>
            <label className="text-green-500 font-semibold">
              {commonTranslations.tax_identification_numbers.add_number}
            </label>
          </button>
        )}
      </div>
      <ErrorHint error={error} />
    </div>
  );
};
