import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import accepted from '../../assets/accepted.png';
import { getTranslations } from '../../redux/form/formSelectors';
import MarkdownWrapper from '../common/MarkdownWrapper';
import { LoadingIcon } from '../common/LoadingIcon';
import { redirectToMarketplace } from '../../redux/auth/authThunk';
import { clearErrors } from '../../redux/notification/notificationActions';

export const VehicleMarketplaceEntryScreen: React.FC = () => {
  const dispatch = useDispatch();
  const commonTranslations = useSelector(getTranslations);

  const [isRedirecting, setIsRedirecting] = React.useState(false);

  const onRedirectToVehicleMarketplaceClick = useCallback(async () => {
    dispatch(clearErrors());

    setIsRedirecting(true);
    await dispatch(redirectToMarketplace());

    setIsRedirecting(false);
  }, [dispatch]);

  return (
    <div className="flex flex-col gap-6 pt-6 text-center">
      <img
        height={148}
        width={200}
        src={accepted}
        alt={commonTranslations.vehicle_marketplace_screen.title}
        className="mx-auto"
      />
      <div className="flex flex-col gap-4">
        <h1 className="text-2xl font-bold">
          <MarkdownWrapper>
            {commonTranslations.vehicle_marketplace_screen.title}
          </MarkdownWrapper>
        </h1>
        <span className="text-gray-700 text-sm">
          <MarkdownWrapper>
            {commonTranslations.vehicle_marketplace_screen.text}
          </MarkdownWrapper>
        </span>
      </div>
      <div className="flex flex-col w-full mx-auto max-w-sm">
        <button
          type="button"
          className="mx-auto w-full py-3 border-none rounded-full text-center font-bold bg-green-500 text-white"
          onClick={onRedirectToVehicleMarketplaceClick}
          disabled={isRedirecting}
        >
          {isRedirecting ? (
            <LoadingIcon className="text-white animate-spin mx-auto" />
          ) : (
            commonTranslations.vehicle_marketplace_screen.select_vehicle_button
          )}
        </button>
      </div>
    </div>
  );
};
