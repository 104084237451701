import React, { useEffect, useState } from 'react';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { DocumentModal } from './DocumentModal';
import { Button } from '../../Button';
import { NotificationsArea } from '../../NotificationsArea';
import { Loader } from '../../common/Loader';
import { BackArrow } from '../../common/BackArrow';
import { CameraIcon } from '../../common/CameraIcon';
import { InfoCheckpoint } from './InfoCheckpoint';
import { DocumentContentType } from '../../types/DocumentContentType';

interface DocumentExampleProps {
  isOpen: boolean;
  submitting: boolean;
  exampleFileUrl: string;
  documentContentType: DocumentContentType;
  commonTranslations: DriverRegistrationPortalNoAuthService.CommonTranslations;
  selectFileHandler: () => void;
  useCameraHandler: () => void;
  closeHandler: () => void;
}

const EXAMPLE_MIN_HEIGHT = 320;

export const DocumentExample: React.FC<DocumentExampleProps> = ({
  isOpen,
  submitting,
  exampleFileUrl,
  documentContentType,
  commonTranslations,
  selectFileHandler,
  useCameraHandler,
  closeHandler,
}: DocumentExampleProps) => {
  const [isExampleLoaded, setsIsExampleLoaded] = useState(false);
  const [previewHeight, setPreviewHeight] = useState(EXAMPLE_MIN_HEIGHT);
  const commonPreviewTranslations = commonTranslations.document_upload.preview;

  useEffect(() => {
    const img = new Image();
    img.onload = () => setsIsExampleLoaded(true);
    img.src = exampleFileUrl;
  }, [exampleFileUrl]);

  useEffect(() => {
    const screenHeight = (window.screen && window.screen.height) || 670;
    const calculatedHeight = Math.round(screenHeight * 0.5);
    if (calculatedHeight > EXAMPLE_MIN_HEIGHT) {
      setPreviewHeight(calculatedHeight);
    }
  }, []);

  return (
    <DocumentModal isOpen={isOpen} onClose={closeHandler}>
      <div className="bg-gray-900 h-full" data-test="upload_preview">
        <div className="block ">
          <div className="h-full overflow-auto">
            <div className="bg-gray-900">
              <button onClick={closeHandler} type="button">
                <div className="object-contain p-3">
                  <BackArrow />
                </div>
              </button>
            </div>
            <div className="w-full bg-gray-900 pb-[260px] relative">
              {documentContentType !== DocumentContentType.PHOTO && (
                <div className="absolute right-[20px] top-[-4px] text-white text-sm px-3 py-1 bg-orange-500 rounded-lg">
                  {commonTranslations.document_upload.document_upload_example}
                </div>
              )}
              {isExampleLoaded ? (
                <img
                  data-test="example_preview"
                  className="object-contain w-full p-3 min-h-[80px] max-h-[400px]"
                  src={exampleFileUrl}
                  alt="document preview"
                  style={{ maxHeight: `${previewHeight}px` }}
                />
              ) : (
                <div className="h-[80px]">
                  <Loader
                    data-test="example_preview_loader"
                    textColor="text-gray-700"
                    id="document_upload_example_loader"
                  />
                </div>
              )}
              <div className="pt-3 px-3 text-xl text-gray-200">
                {
                  commonTranslations.document_upload
                    .document_upload_requirements
                }
              </div>
              <div className="py-3 px-5 flex flex-col gap-3">
                <InfoCheckpoint
                  title={commonPreviewTranslations.point_1.title}
                  text={commonPreviewTranslations.point_1.description}
                  textColor="text-gray-500"
                  version="transparent"
                />
                <InfoCheckpoint
                  title={commonPreviewTranslations.point_2.title}
                  text={commonPreviewTranslations.point_2.description}
                  textColor="text-gray-500"
                  version="transparent"
                />
                <InfoCheckpoint
                  title={commonPreviewTranslations.point_3.title}
                  text={commonPreviewTranslations.point_3.description}
                  textColor="text-gray-500"
                  version="transparent"
                />
                <InfoCheckpoint
                  title={commonPreviewTranslations.point_4.title}
                  text={commonPreviewTranslations.point_4.description}
                  textColor="text-gray-500"
                  version="transparent"
                />
              </div>
            </div>
          </div>
          <div className="absolute drop-shadow-frombottom bottom-0 left-0 bg-white w-full rounded-t-2xl">
            <div className="block -mt-10 pt-1">
              <div className="px-8 py-3 flex flex-row content-center justify-center">
                <div
                  id="use_camera_button"
                  data-test="use_camera"
                  className="border-none rounded-full px-3 font-bold text-white bg-green-500 min-w-[240px] max-w-[400px] cursor-pointer"
                  onClick={useCameraHandler}
                  onKeyPress={useCameraHandler}
                  role="button"
                  tabIndex={0}
                >
                  <div className="flex flex-row justify-center content-center gap-3 p-3">
                    <div>
                      <CameraIcon />
                    </div>
                    <div>
                      {commonTranslations.document_upload.use_camera_button}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col mt-3 rounded-t-lg relative bg-white">
              <div className="h-px bg-gray-600 mx-5" />
              <div className="-mt-3 flex flex-row align-center justify-center text-gray-700">
                <div className="bg-white px-5">or</div>
              </div>
              <div className="-mt-2 px-8 pb-8 flex flex-row content-center justify-center">
                <div className="min-w-[240px] max-w-[400px]">
                  <Button
                    id="select_file_button"
                    data-test="select_file"
                    onClick={selectFileHandler}
                    color="bg-gray-200"
                    loadingTextColor="text-gray-500"
                    textColor={submitting ? 'text-gray-500' : 'text-gray-900'}
                    label={
                      commonTranslations.document_upload.select_file_button
                    }
                    disabled={submitting}
                    loading={false}
                  />
                </div>
              </div>
            </div>
            <NotificationsArea />
          </div>
        </div>
      </div>
    </DocumentModal>
  );
};
