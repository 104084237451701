import { StylesConfig } from 'react-select';

export interface Choice {
  value: string | number | null;
  title: string;
}

export const isChoice = (v: unknown): v is Choice => {
  return !!v && (v as Choice).value !== undefined;
};

export const customStyles: StylesConfig<Choice> = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#FFFFFF' : '#F4F4F6',
    borderColor: state.isFocused ? '#32BB78' : '#F4F4F6',
    borderWidth: '2px',
    boxShadow: 'none',
    padding: '8px 12px',
    ':hover': {
      ...provided[':hover'],
      borderColor: state.isFocused ? '#32BB78' : '#F4F4F6',
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#2F313F',
    padding: 0,
    transform: state.isFocused ? 'rotate(180deg)' : 'none',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: '#999999',
    padding: '4px 8px',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    color: '#999999',
    marginLeft: '2px',
    marginRight: '10px',
  }),
  menu: (provided) => ({
    ...provided,
    background: '#FFFFFF',
    position: 'absolute',
    left: 0,
    right: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '8px 24px',
    minHeight: '40px',
    lineHeight: '24px',
    backgroundColor: state.isSelected ? '#E0FFF0' : '#FFFFFF',
    color: '#2F313F',
    ':hover': {
      ...provided[':hover'],
      backgroundColor: state.isSelected ? '#E0FFF0' : '#F4F4F6',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: 0,
    paddingTop: '3px',
    paddingBottom: '3px',
  }),
  input: (provided) => ({
    ...provided,
    height: '18px',
  }),
};

export const errorStyles: StylesConfig<Choice> = {
  control: (provided, state) => ({
    ...provided,
    ...(customStyles.control && customStyles.control(provided, state)),
    borderColor: 'rgba(235, 71, 85, 1)',
    ':hover': {
      ...provided[':hover'],
      borderColor: 'rgba(235, 71, 85, 1)',
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    ...(customStyles.dropdownIndicator &&
      customStyles.dropdownIndicator(provided, state)),
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    ...(customStyles.clearIndicator &&
      customStyles.clearIndicator(provided, state)),
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    ...(customStyles.indicatorSeparator &&
      customStyles.indicatorSeparator(provided, state)),
  }),
  menu: (provided, state) => ({
    ...provided,
    ...(customStyles.menu && customStyles.menu(provided, state)),
  }),
  option: (provided, state) => ({
    ...provided,
    ...(customStyles.option && customStyles.option(provided, state)),
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    ...(customStyles.valueContainer &&
      customStyles.valueContainer(provided, state)),
  }),
  input: (provided, state) => ({
    ...provided,
    ...(customStyles.input && customStyles.input(provided, state)),
  }),
};
