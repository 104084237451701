import React from 'react';
import { LoadingIcon } from './LoadingIcon';

interface LoaderProps {
  id: string;
  textColor: string;
  margin_y?: string;
}

export const Loader: React.FC<LoaderProps> = ({
  id,
  textColor,
  margin_y,
}: LoaderProps) => (
  <label
    htmlFor={id}
    className={`${
      margin_y || 'my-9'
    } w-6 inset-x-0 mx-auto absolute animate-spin`}
  >
    <LoadingIcon className={textColor} />
  </label>
);

Loader.defaultProps = {
  margin_y: 'my-9',
};
