import React from 'react';
import checkMarkGreenImage from '../../../assets/check-mark-green.svg';

interface PreviewCheckpointProps {
  text: string;
}

export const PreviewCheckpoint: React.FC<PreviewCheckpointProps> = ({
  text,
}: PreviewCheckpointProps) => {
  return (
    <div className="flex flex-row m-1 mx-6 tablet:my-6">
      <div className="inline-block mr-2 rtl:ml-2">
        <img
          width={24}
          height={24}
          className="object-contain max-w-none"
          src={checkMarkGreenImage}
          alt="check mark"
          color="#34D186"
        />
      </div>
      <div>
        <span className="mt-0.5 block text-sm text-gray-800">{text}</span>
      </div>
    </div>
  );
};
