import React, { ReactElement } from 'react';

export interface LinkProps {
  text: string;
  additionalStyles?: string;
  onClick: () => void;
}

export const LinkElement: React.FC<LinkProps> = (
  props: LinkProps
): ReactElement => {
  return (
    <button
      onClick={props.onClick}
      className={`text-green-500 ${props.additionalStyles}`}
      type="button"
    >
      {props.text}
    </button>
  );
};

export const MemoizedLinkElement = React.memo(LinkElement);
