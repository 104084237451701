import React from 'react';

interface StepIndicatorProps {
  stepsCount: number;
  currentStep: number;
}

enum StepStateClassName {
  COMPLETED = 'bg-green-500',
  ACTIVE = 'bg-green-200',
  NOT_STARTED = 'bg-gray-200',
}

interface Step {
  key: number;
  className: StepStateClassName;
}

export const StepIndicator: React.FC<StepIndicatorProps> = ({
  stepsCount,
  currentStep,
}: StepIndicatorProps) => {
  const steps: Step[] = [];

  for (let i = 1; i <= stepsCount; i += 1) {
    if (i < currentStep) {
      steps.push({
        key: i,
        className: StepStateClassName.COMPLETED,
      });
    } else if (i === currentStep) {
      steps.push({
        key: i,
        className: StepStateClassName.ACTIVE,
      });
    } else {
      steps.push({
        key: i,
        className: StepStateClassName.NOT_STARTED,
      });
    }
  }

  return (
    <div className="flex flex-row space-x-1 items-center justify-center rtl:space-x-reverse">
      {steps.map((step) => (
        <div
          key={step.key}
          className={`w-12 tablet:w-16 h-1 rounded-full ${step.className}`}
        />
      ))}
    </div>
  );
};
