import React, { ReactElement, useState } from 'react';
import { getSignupLandingLink, getDriverLoginLink } from '../../common/helpers';
import { useSelector } from '../../redux/store';
import { getTranslations } from '../../redux/form/formSelectors';
import { Button } from '../Button';

interface LoginAndNewSignupLinksProperties {
  showLogin?: boolean;
  boldFont?: boolean;
  isButton?: boolean;
}

export const LoginAndNewSignupLinks = ({
  showLogin,
  boldFont,
  isButton,
}: LoginAndNewSignupLinksProperties): ReactElement => {
  const loginLink = getDriverLoginLink();
  const signupLink = getSignupLandingLink();
  const t = useSelector(getTranslations);
  const [isLoading, setLoading] = useState(false);

  const handleOnClick = React.useCallback((url: string) => {
    setLoading(true);
    window.location.replace(url);
  }, []);

  const textClassName = boldFont
    ? 'text-green-700 font-bold'
    : 'text-green-700';

  return (
    <div className="flex flex-col text-center gap-4">
      {showLogin && (
        <div>
          <button
            type="button"
            onClick={() => handleOnClick(loginLink)}
            className={textClassName}
          >
            {t.common_labels.login}
          </button>
        </div>
      )}
      <div>
        {isButton ? (
          <Button
            id="newSignupButton"
            onClick={() => handleOnClick(signupLink)}
            color={isLoading ? 'bg-green-200' : 'bg-green-500'}
            loadingTextColor="text-green-500"
            label={t.common_labels.start_new_registration}
            disabled={isLoading}
            loading={isLoading}
          />
        ) : (
          <button
            type="button"
            onClick={() => handleOnClick(signupLink)}
            className={textClassName}
          >
            {t.common_labels.start_new_registration}
          </button>
        )}
      </div>
    </div>
  );
};

LoginAndNewSignupLinks.defaultProps = {
  showLogin: false,
  boldFont: true,
  isButton: false,
};
