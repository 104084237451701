import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import React from 'react';
import { FinalScreenField } from './FinalScreenField';
import submitted from '../../assets/submitted.png';
import MarkdownWrapper from '../common/MarkdownWrapper';
import { useStatusPolling } from '../../common/statusPolling';

interface SubmittedFinalScreenProps {
  finalScreen: DriverRegistrationPortalNoAuthService.FinalScreen;
}

export const SubmittedFinalScreen: React.FC<SubmittedFinalScreenProps> = (
  props
) => {
  useStatusPolling();
  const { finalScreen } = props;
  const mappedFields = React.useMemo(() => {
    return (
      finalScreen.fields &&
      finalScreen.fields.map(
        (field: DriverRegistrationPortalNoAuthService.Field) => {
          return <FinalScreenField key={field.name} fieldDefinition={field} />;
        }
      )
    );
  }, [finalScreen]);

  return (
    <div className="flex flex-col gap-6 pt-6 text-center">
      <img
        height={148}
        width={200}
        src={submitted}
        alt={finalScreen.translations.title}
        className="mx-auto"
      />
      <div className="flex flex-col gap-4">
        <h1 className="text-2xl font-bold">
          <MarkdownWrapper>{finalScreen.translations.title}</MarkdownWrapper>
        </h1>
        {finalScreen.translations.subtitle && (
          <div className="text-gray-700 text-sm mt-1">
            <MarkdownWrapper>
              {finalScreen.translations.subtitle}
            </MarkdownWrapper>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-2">{mappedFields}</div>
    </div>
  );
};
