import React from 'react';
import MarkdownWrapper from '../common/MarkdownWrapper';
import {
  getDeclineReasons,
  getTranslations,
} from '../../redux/form/formSelectors';
import { useSelector } from '../../redux/store';
import { LoginAndNewSignupLinks } from '../common/LoginAndNewSignupLinks';
import declined from '../../assets/declined.png';

export const DeclinedFinalScreen: React.FC = () => {
  const commonTranslations = useSelector(getTranslations);
  const declineReasons = useSelector(getDeclineReasons);
  const declineReasonsWrapped = declineReasons.length
    ? declineReasons.map((item) => {
        return <MarkdownWrapper>{item}</MarkdownWrapper>;
      })
    : [];

  return (
    <div className="flex flex-col gap-6 pt-6 text-center">
      <img
        height={148}
        width={200}
        src={declined}
        alt={commonTranslations.declined_screen.title}
        className="mx-auto"
      />
      <div className="flex flex-col gap-4">
        <h1 className="text-2xl font-bold">
          <MarkdownWrapper>
            {commonTranslations.declined_screen.title}
          </MarkdownWrapper>
        </h1>
        {declineReasonsWrapped.length && (
          <div className="flex flex-col gap-2">{declineReasonsWrapped}</div>
        )}
      </div>
      <LoginAndNewSignupLinks showLogin={false} isButton />
    </div>
  );
};
