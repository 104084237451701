import * as React from 'react';
import { Loader } from './common/Loader';

interface ButtonProps {
  id: string;
  onClick: () => void;
  color: string;
  label: string;
  disabled: boolean;
  loading: boolean;
  loadingTextColor?: string;
  textColor?: string;
}

export const Button: React.FC<ButtonProps> = ({
  id,
  onClick,
  color,
  label,
  disabled,
  loading,
  loadingTextColor,
  textColor,
}: ButtonProps) => {
  const cursor = disabled ? 'cursor-not-allowed' : 'cursor-pointer';
  return (
    <div className="relative">
      {loading && (
        <Loader
          textColor={loadingTextColor ?? 'text-gray-500'}
          id={id}
          margin_y="my-7"
        />
      )}
      <span>
        <input
          id={id}
          type="submit"
          disabled={disabled}
          className={`w-full tablet:w-auto m-0 mt-4 px-8 py-3 border-none rounded-full text-center font-bold break-words whitespace-normal ${
            textColor ?? 'text-white'
          } ${color} ${cursor}`}
          onClick={onClick}
          value={label}
        />
      </span>
    </div>
  );
};

Button.defaultProps = {
  textColor: undefined,
  loadingTextColor: undefined,
};
