import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { v4 as uuidv4 } from 'uuid';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { push } from 'connected-react-router';
import { handleVerificationErrorResponse } from '../../api/ApiErrorsHandler';
import {
  getAppMarketingData,
  getAppVersionFromQuery,
  getLanguageFromUrl,
  getPreviewFlowIdFromQuery,
  getVisitorTrackingData,
  getWebMarketingDataForCreateAccountRequest,
  getWebRequestParams,
  saveHashToCookies,
} from '../../common/helpers';
import { IAppState, ThunkMiddlware } from '../types';
import { clearErrors } from '../notification/notificationActions';
import { GoogleAnalytics } from '../../common/googleAnalytics';
import { getCookie } from '../../common/cookies';

import { getLanguage } from '../localization/localizationSelectors';
import {
  setCaptchaNotNeeded,
  setConfirmationLoaded,
  setConfirmationLoading,
  setCreateAccountResult,
  setVerificationChannel,
  setVerificationData,
  setVerificationLoaded,
  setVerificationLoading,
} from './verificationActions';
import NoResponseError from '../../api/errors/NoResponseError';
import { getCaptchaParameters } from './verificationSelectors';
import {
  displayAppPromotionScreen,
  getContinueSignupUrl,
  redirectAfterAccountCreated,
  shouldShowAppPromoScreen,
} from './verificationHelpers';
import * as LocalStorage from '../../common/LocalStorageService';

export const startVerification: ActionCreator<
  ThunkAction<Promise<void>, IAppState, ThunkMiddlware, Action<void>>
> =
  (
    token: string,
    channel: DriverRegistrationPortalNoAuthService.VerificationCodeChannel = DriverRegistrationPortalNoAuthService
      .VerificationCodeChannel.SMS,
    challenge?: DriverRegistrationPortalNoAuthService.CaptchaChallengeToken
  ) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => IAppState,
    { registrationApi }
  ) => {
    dispatch(clearErrors());
    dispatch(setVerificationLoading());
    dispatch(setVerificationChannel(channel));

    const language = getLanguageFromUrl() ?? getLanguage(getState());

    try {
      const response = await registrationApi.startVerification({
        token,
        verification_code_channel: channel,
        ...getWebRequestParams(language),
        ...getVisitorTrackingData(),
        ...(challenge && { challenge }),
      });

      if (response) {
        dispatch(setCaptchaNotNeeded());
        dispatch(setVerificationData(response));
      } else {
        throw new NoResponseError('No response for /startVerification');
      }
    } catch (err) {
      dispatch(setCaptchaNotNeeded());
      handleVerificationErrorResponse(err, dispatch, getState);
      const captchaParameters = getCaptchaParameters(getState());
      if (!captchaParameters) {
        dispatch(setVerificationLoaded());
      }
    }
  };

export const createAccount: ActionCreator<
  ThunkAction<Promise<void>, IAppState, ThunkMiddlware, Action<void>>
> =
  (token: string, conflictResolutionConsent: boolean) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => IAppState,
    { registrationApi }
  ) => {
    dispatch(clearErrors());

    const state = getState();
    const language = getLanguage(state);

    dispatch(setConfirmationLoading());

    const timestampInSeconds = Math.floor(Date.now() / 1000);
    const messageId = `${uuidv4()}driver${timestampInSeconds}`;

    dispatch(setCreateAccountResult(null));

    try {
      const response = await registrationApi.createAccount(
        { ...getWebRequestParams(language) },
        {
          token,
          app_marketing_data: getAppMarketingData(),
          web_marketing_data: {
            ...getWebMarketingDataForCreateAccountRequest(),
            message_id: messageId,
          },
          visitor_tracking_data: getVisitorTrackingData(),
          app_version: getAppVersionFromQuery() ?? undefined,
          preview_flow_id: getPreviewFlowIdFromQuery() ?? undefined,
          resolve_conflict_consent: conflictResolutionConsent,
        }
      );

      const registrationHash = response.registration_hash;
      const isNewRegistrationCreated = response.is_new_registration_created;

      saveHashToCookies(registrationHash);
      dispatch(setCreateAccountResult(response));

      if (isNewRegistrationCreated) {
        const referralCode = getCookie('referralCode') || '';
        GoogleAnalytics.onNewSignup(registrationHash, referralCode, messageId);
      }

      if (response.refresh_token) {
        LocalStorage.setItem(
          LocalStorage.refreshTokenKey,
          response.refresh_token
        );
      }

      const showAppPromoScreen = shouldShowAppPromoScreen(
        getAppVersionFromQuery(),
        response.refresh_token,
        response.app_promotion
      );

      if (showAppPromoScreen) {
        dispatch(setConfirmationLoaded());
        displayAppPromotionScreen(dispatch, getState);
      } else {
        redirectAfterAccountCreated(dispatch, getState);
      }
    } catch (err) {
      handleVerificationErrorResponse(err, dispatch, getState);
      dispatch(setConfirmationLoaded());
    }
  };

export const confirmVerification: ActionCreator<
  ThunkAction<Promise<void>, IAppState, ThunkMiddlware, Action<void>>
> =
  (token: string, code: string) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => IAppState,
    { registrationApi }
  ) => {
    dispatch(clearErrors());

    const state = getState();
    const language = getLanguage(state);

    try {
      dispatch(setConfirmationLoading());

      await registrationApi.confirmVerification({
        token,
        code,
        ...getVisitorTrackingData(),
        ...getWebRequestParams(language),
      });

      dispatch(createAccount(token, false));
    } catch (err) {
      handleVerificationErrorResponse(err, dispatch, getState);
      dispatch(setConfirmationLoaded());
    }
  };

export const continueInBrowser: ActionCreator<
  ThunkAction<Promise<void>, IAppState, ThunkMiddlware, Action<void>>
> = () => async (dispatch: Dispatch<any>, getState: () => IAppState) => {
  const url = getContinueSignupUrl(getState);
  dispatch(push(url));
};
