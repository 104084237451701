import * as React from 'react';

export interface FieldLabelProps {
  text?: string;
  isRequired?: boolean;
}

export const FieldLabel: React.FC<FieldLabelProps> = ({ text, isRequired }) => {
  return (
    <label
      htmlFor={text}
      className={`text-black font-bold text-sm text-left rtl:text-right ${
        isRequired ? "after:text-red-700 after:content-['_*']" : ''
      }`}
    >
      {text}
    </label>
  );
};
