import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { FleetRegistrationNoAuthService } from '@bolteu/bolt-server-api-driver-portal';

const {
  DriverRegistrationLogLastStep,
  FrontendFieldDefinitionType,
  ConditionType,
  UploadStatus,
} = DriverRegistrationPortalNoAuthService;

export const getMockCommonTranslations =
  (): DriverRegistrationPortalNoAuthService.CommonTranslations => ({
    document_upload: {
      upload_button_label: '+ Upload file',
      upload_document_button_label: '+ Upload document',
      upload_failed_try_again: 'Upload failed. Please try again.',
      required_label: 'Required *',
      expire_label: 'Expires',
      back_button_label: 'Back',
      next_button_label: 'Next',
      check_upload_modal: 'Please check errors inside document upload dialog',
      document_upload_example: 'Example',
      your_document: 'Your document',
      document_upload_uploaded_label: 'Uploaded',
      document_upload_requirements: 'Requirements',
      use_camera_button: 'Use Camera',
      select_file_button: 'Select File',
      preview: {
        choose_photo_button_label: 'Retake photo',
        next_button_label: 'Photo is readable',
        change_photo_button_label: 'Change photo',
        readability_title: 'Check readability',
        readability_description:
          'Make sure your document details are clear to read, without blur or glare.',
        point_1: {
          title: 'Full document',
          description:
            'Make sure the entire document fits in the frame. This helps to verify it faster. ',
        },
        point_2: {
          title: 'Full document',
          description:
            'Make sure the entire document fits in the frame. This helps to verify it faster. ',
        },
        point_3: {
          title: 'Full document',
          description:
            'Make sure the entire document fits in the frame. This helps to verify it faster. ',
        },
        point_4: {
          title: 'Full document',
          description:
            'Make sure the entire document fits in the frame. This helps to verify it faster. ',
        },
        confirm_title: 'Confirm the following:',
        confirm_button_label: 'Confirm',
        text_document: {
          point_1: {
            description:
              'The document is fully visible and nothing is covering the information',
          },
          point_2: {
            description:
              'All information is clearly readable and evenly lit, with no blur or glare',
          },
          point_3: {
            description: 'This is a real document, not a photo of a screen',
          },
        },
        face_closeup: {
          point_1: {
            description: 'Your face is clearly visible inside the frame',
          },
          point_2: {
            description:
              'The photo is clear and evenly lit, with no blur or glare',
          },
          point_3: {
            description:
              'There are no sunglasses or accessories covering your face (prescription glasses are fine)',
          },
        },
        other_photograph: {
          point_1: {
            description: 'This is a live picture, not a photo of a screen',
          },
          point_2: {
            description:
              'The photo is clear and evenly lit, with no blur or glare',
          },
        },
      },
      verification: {
        verification_title: 'Check the details',
        verification_description:
          'Check all your personal details and correct them if necessary.',
        document_details_title: 'Document details',
        document_details_description:
          'Please provide the following details of the document',
        confirm_button_label: 'Confirm',
        security_message:
          "For security reasons we can't expose files that are stored on our servers",
      },
      unknown_error:
        'Something went wrong with the document upload. Check your internet connection and try again.',
      status_label: {
        declined: 'Declined',
        in_review: 'In review',
        optional: 'Optional',
        uploaded: 'Uploaded',
      },
    },
    document_resubmission: {
      document_resubmission_title: 'Driver documents',
      document_resubmission_subtitle:
        "We're legally required to ask you for some documents to sign you up as driver. " +
        'Documents scans and quality photos are accepted.',
    },
    common_actions: {
      add_new_item: 'Add new item',
      save: 'Save',
      delete: 'Delete',
      refresh: 'Refresh',
    },
    common_labels: {
      login: 'Login',
      start_new_registration: 'Start new registration',
      page_not_found: 'Page not found',
    },
    tax_identification_numbers: {
      add_number: 'Add other Tax ID number',
      country_placeholder: 'Select your country',
      country_required: 'Select your country',
      number_placeholder: 'Enter tax ID number',
      number_required: 'Enter tax ID number',
      number_format_invalid: 'Incorrect tax ID number',
    },
    app_promotion: {
      your_account_created: 'Your account has been created',
      continue_registration_in_app:
        'You can continue registration right here or in the Bolt Driver mobile app. Simply use your phone number or email to log in.',
      get_driver_app: 'Get Bolt Driver app',
      driver_app_is_outdated: 'Your Bolt Driver app is outdated',
      update_driver_app:
        'Please, make sure your Bolt Driver app is updated to the latest version. It is required for using new driver account.',
      please_download_app_text:
        'Please download the app on your mobile device from Google Play or App Store and finish registration there to start driving.',
      you_can_login: 'You can log in with the same phone number.',
      download_on_google_play: 'Download from Google Play',
      download_on_app_store: 'Download from App Store',
      scan_qr_code:
        'Scan the QR code on your mobile device to download Bolt Driver app:',
      or_use_links: 'Or use the following links:',
      continue_in_browser: 'Continue in browser',
    },
    verification: {
      enter_code: 'Enter code',
      code_sent_message: 'A verification code was sent to',
      resend_code_in: 'Resend code in',
      code_missing: 'Please enter your verification code',
      code_resent: 'A new code was resent to',
      code_resent_by_call: 'Your will receive a callback to',
      resend_code_sms: 'Resend code',
      resend_code_whatsapp: 'Resend via WhatsApp',
      resend_code_call: 'Request callback',
      did_not_get_the_code: 'Did not get the code?',
      resend_to_try_again: 'Resend code to try again.',
      continue_new_registration: 'Continue new registration',
      login_to_existing_registration: 'Login to existing registration',
      verify_phone_number: 'Choose the verification method',
      verification_code_will_be_sent_to: 'Verification code will be sent to:',
      get_code_via_whatsapp: 'Get code via WhatsApp',
      get_code_via_sms: 'Get code via SMS',
    },
    final_screen: {
      driver_account_approved_title: 'Your driver account is approved',
      driver_account_approved_description:
        'Now you can log in as a driver. The app might ask for some permissions required for its correct work.',
      driver_account_approved_login_as_driver: 'Log in as driver',
      install_bolt_app: 'Install Bolt Driver app to start driving',
    },
    declined_screen: {
      title: 'This application was declined',
    },
    common_unknown_error: 'Something went wrong',
    captcha_security_check: {
      title: 'Security check',
      text: 'Please check the box if you’re human.',
    },
    autosave: {
      saving: 'Saving...',
      saved: 'Saved',
    },
    vehicle_marketplace_screen: {
      title: 'Congrats! Your Driver account is almost ready.',
      text: 'We will check the information provided and will contact you by email. In the meantime, select a vehicle to drive.',
      select_vehicle_button: 'Select a vehicle',
    },
    vehicle_types: {
      motorbikes: 'Motorbikes',
      cars: 'Cars',
      all: 'All',
      vehicle_type_placeholder: 'Vehicle Type',
      vehicle_manufacturer_placeholder: 'Manufacturer',
      vehicle_model_placeholder: 'Model',
    },
  });

export const getMockSignupFlowResponse =
  (): DriverRegistrationPortalNoAuthService.GetFieldsResponse => ({
    language: 'en',
    languages: [
      {
        key: 'en',
        name: 'English',
        direction: DriverRegistrationPortalNoAuthService.ScriptDirection.LTR,
      },
    ],
    common_translations: getMockCommonTranslations(),
    current_step: DriverRegistrationLogLastStep.STEP2,
    steps: [
      {
        id: DriverRegistrationLogLastStep.STEP1,
        translations: {
          label: 'Contacts',
          title: '',
          subtitle: '',
        },
        fields: [],
      },
      {
        id: DriverRegistrationLogLastStep.STEP2,
        translations: {
          label: 'Personal & vehicle info',
          title: 'Personal information and vehicle details',
          subtitle:
            'Only your first name and vehicle details are visible to clients during the booking',
        },
        fields: [
          {
            name: 'first_name',
            type: FrontendFieldDefinitionType.INPUT,
            current_value: null,
            is_required: true,
            translations: {
              label: 'First name',
              description: '',
              placeholder: '',
            },
          },
          {
            name: 'last_name',
            type: FrontendFieldDefinitionType.INPUT,
            current_value: null,
            is_required: true,
            translations: {
              label: 'Last name',
              description: '',
              placeholder: '',
            },
          },
          {
            name: 'language',
            type: FrontendFieldDefinitionType.SELECT,
            current_value: null,
            is_required: true,
            translations: {
              label: 'Language',
              description: '',
              placeholder: '',
            },
            select_values: [
              { title: 'English', value: 'en' },
              { title: 'Suomi', value: 'fi' },
              { title: 'Eesti', value: 'et' },
            ],
          },
          {
            name: 'referral_code',
            type: FrontendFieldDefinitionType.INPUT,
            current_value: null,
            is_required: false,
            translations: {
              label: 'Referral code',
              description: '',
              placeholder: '',
            },
          },
          {
            name: 'has_fleet',
            type: FrontendFieldDefinitionType.TEXT,
            current_value: null,
            is_required: false,
            translations: {
              label:
                '**Have multiple vehicles?**<br/>[Sign up as a fleet owner→](https://fleets.bolt.eu/signup)',
              description: '',
              placeholder: '',
            },
          },
          {
            name: 'has_car',
            type: FrontendFieldDefinitionType.CHECKBOX,
            current_value: 0,
            is_required: false,
            translations: {
              label: 'I have a vehicle that I will drive',
              description: '',
              placeholder: '',
            },
          },
          {
            name: 'car_model_id',
            type: FrontendFieldDefinitionType.SELECT,
            select_values: [
              {
                title: 'Mercedes Benz',
                value: 1,
                subitems: [
                  { title: 'A-class', value: 101 },
                  { title: 'C-class', value: 102 },
                  { title: 'E-class', value: 103 },
                  { title: 'G-class', value: 104 },
                ],
              },
              {
                title: 'Toyota',
                value: 2,
                subitems: [
                  { title: 'Avalon', value: 201 },
                  { title: 'Camry', value: 202 },
                  { title: 'Corolla', value: 203 },
                  { title: 'Prius', value: 204 },
                ],
              },
            ],
            is_required: true,
            current_value: null,
            translations: {
              label: 'Vehicle manufacturer|Vehicle model',
              description: '',
              placeholder: '',
            },
            show_if: {
              has_car: { type: ConditionType.CHECKBOX, equals: true },
            },
          },
          {
            name: 'year',
            type: FrontendFieldDefinitionType.SELECT,
            is_required: true,
            current_value: null,
            select_values: [
              { title: '2020', value: 2020 },
              { title: '2019', value: 2019 },
              { title: '2018', value: 2018 },
              { title: '2017', value: 2017 },
              { title: '2016', value: 2016 },
              { title: '2015', value: 2015 },
              { title: '2014', value: 2014 },
              { title: '2013', value: 2013 },
              { title: '2012', value: 2012 },
            ],
            translations: {
              label: 'Vehicle year',
              description: '',
              placeholder: '',
            },
            show_if: {
              has_car: { type: ConditionType.CHECKBOX, equals: true },
            },
          },
          {
            name: 'license_plate',
            type: FrontendFieldDefinitionType.INPUT,
            is_required: true,
            current_value: null,
            translations: {
              label: 'License plate',
              placeholder: '717 TTP',
              description: '',
            },
            show_if: {
              has_car: { type: ConditionType.CHECKBOX, equals: true },
            },
          },
          {
            name: 'car_color',
            type: FrontendFieldDefinitionType.SELECT,
            select_values: [
              { title: 'Beige', value: 'beige' },
              { title: 'Black', value: 'black' },
              { title: 'Blue', value: 'blue' },
              { title: 'Brown', value: 'brown' },
              { title: 'Gold', value: 'gold' },
            ],
            is_required: true,
            current_value: null,
            translations: {
              label: 'Vehicle color',
              description: '',
              placeholder: '',
            },
            show_if: {
              has_car: { type: ConditionType.CHECKBOX, equals: true },
            },
          },
        ],
      },
      {
        id: DriverRegistrationLogLastStep.STEP3,
        translations: {
          label: 'Documents & fees',
          title: '',
          subtitle: '',
        },
        fields: [],
      },
      {
        id: DriverRegistrationLogLastStep.STEP4,
        translations: {
          label: 'Documents',
          title: '',
          subtitle: '',
        },
        fields: [
          {
            name: 'driver_licence_number',
            type: FrontendFieldDefinitionType.INPUT,
            show_if: {
              document_upload: {
                type: ConditionType.DOCUMENT_UPLOAD,
                upload_status: UploadStatus.IN_MODAL_DIALOG,
                document_type_key: 'driver_licence',
              },
            },
            is_required: true,
            current_value: '12345678',
            translations: {
              label: 'Driver licence number',
              description: '',
              placeholder: '',
            },
          },
          {
            name: 'driver_licence',
            type: FrontendFieldDefinitionType.DOCUMENT_UPLOAD,
            current_value: null,
            is_required: true,
            is_expiry_required: true,
            translations: {
              label: 'Driver licence',
              placeholder: '',
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc haec primum fortasse audientis servire debemus. Hoc dixerit potius Ennius: Nimium boni est, cui nihil est mali. Ita multa dicunt, quae vix intellegam.',
            },
          },
          {
            name: 'transport_licence',
            type: FrontendFieldDefinitionType.DOCUMENT_UPLOAD,
            current_value: {
              document_name: 'test-document.png',
              document_id: 1,
              document_expires: null,
            } as FleetRegistrationNoAuthService.FleetUploadedDocument,
            is_required: false,
            translations: {
              label: 'Transport licence',
              placeholder: '',
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc haec primum fortasse audientis servire debemus. Hoc dixerit potius Ennius: Nimium boni est, cui nihil est mali. Ita multa dicunt, quae vix intellegam.',
            },
          },
        ],
      },
      {
        id: DriverRegistrationLogLastStep.STEP5,
        translations: {
          label: 'Payment details',
          title: '',
          subtitle: '',
        },
        fields: [],
      },
    ],
    final_screen: {
      translations: {
        title: 'Final screen',
        subtitle: 'Congratulations on finishing the registration successfully!',
        label: '',
      },
      fields: [
        {
          name: 'confirmation_text',
          type: FrontendFieldDefinitionType.TEXT,
          current_value: null,
          is_required: true,
          translations: {
            label:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc haec primum fortasse audientis servire debemus. Hoc dixerit potius Ennius: Nimium boni est, cui nihil est mali. Ita multa dicunt, quae vix intellegam.',
            placeholder: '',
            description: '',
          },
        },
        {
          name: 'educational_video',
          type: FrontendFieldDefinitionType.EMBEDDED_RESOURCE,
          current_value: null,
          is_required: true,
          translations: {
            label: 'Please watch this video!',
            placeholder: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
            description: '',
          },
        },
        {
          name: 'educational_presentation',
          type: FrontendFieldDefinitionType.EMBEDDED_RESOURCE,
          current_value: null,
          is_required: true,
          translations: {
            label: 'Please go through these slides!',
            placeholder:
              'https://docs.google.com/presentation/d/e/2PACX-1vRR64d3rxZHt-V6gXvF3CQltv1tnP2AqXRtQaWD89fOs7AvXQlRa-va9hWzacRLoJl6A1S1gJdzKtRX/embed?start=false&loop=false&delayms=60000',
            description: '',
          },
        },
        {
          name: 'remote_training',
          type: FrontendFieldDefinitionType.LINK_BUTTON,
          current_value: null,
          is_required: true,
          translations: {
            label: 'Watch online training',
            placeholder: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
            description: '',
          },
        },
      ],
    },
    driver_registration_log_status:
      DriverRegistrationPortalNoAuthService.DriverRegistrationLogStatus
        .SUBMITTED,
    flow_id: 1000,
    country: 'gb',
    features: {
      autosave: {
        enabled: false,
      },
      banners: {
        items: [],
      },
      vehicle_marketplace: {
        enabled: false,
      },
    },
  });

export const getMockSupportedLanguagesResponse =
  (): DriverRegistrationPortalNoAuthService.GetSupportedLanguagesResponse => ({
    languages: [
      {
        key: 'en',
        name: 'English',
        direction: DriverRegistrationPortalNoAuthService.ScriptDirection.LTR,
      },
    ],
  });
