import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { FleetRegistrationNoAuthService } from '@bolteu/bolt-server-api-driver-portal';
import { DocumentUploadPreview } from './document_upload/DocumentUploadPreview';
import { DocumentUploadVerification } from './document_upload/DocumentUploadVerification';
import ErrorHint from '../common/ErrorHint';
import {
  getFieldError,
  getFieldErrors,
} from '../../redux/notification/notificationsSelectors';
import { ExtendedField, IAppState } from '../../redux/types';
import { unixToDateString } from '../../common/helpers';
import {
  clearErrors,
  FieldValidationError,
} from '../../redux/notification/notificationActions';
import {
  deleteDocument,
  fallbackDocumentUploadForEnhancedCapture,
} from '../../redux/form/formThunk';
import { Button } from '../Button';
import MarkdownWrapper from '../common/MarkdownWrapper';
import { DocumentExample } from './document_upload/DocumentExample';
import { DocumentUploadPreviewWithExample } from './document_upload/DocumentUploadPreviewWithExample';
import { DocumentContentType } from '../types/DocumentContentType';
import { DeviceData } from '../../common/deviceData';
import { DocumentStatusLabel } from './document_upload/DocumentStatusLabel';
import { DocumentOptionalLabel } from './document_upload/DocumentOptionalLabel';
import partnerDriverApiClient from '../../api/PartnerRegistrationApi';
import { Logger } from '../../common/logger';
import { getDeeplinkScheme } from '../../common/environment';

const UPLOADED_STATUSES = [
  DriverRegistrationPortalNoAuthService.DocumentResultStatus.IN_REVIEW,
  DriverRegistrationPortalNoAuthService.DocumentResultStatus.CONFIRMED,
  DriverRegistrationPortalNoAuthService.DocumentResultStatus.DECLINED,
];
const CAN_REUPLOAD_STATUSES = [
  DriverRegistrationPortalNoAuthService.DocumentResultStatus.DECLINED,
];
export enum DocumentUploadStep {
  INITIAL,
  EXAMPLE,
  PREVIEW,
  VERIFICATION,
}

interface DocumentUploadProps {
  commonTranslations: DriverRegistrationPortalNoAuthService.CommonTranslations;
  field: ExtendedField;
  step: DriverRegistrationPortalNoAuthService.Step;
  hash: string;
  error?: string;
  fieldErrors?: FieldValidationError[];
  updateHandler: (
    changed: FleetRegistrationNoAuthService.FleetUploadedDocument
  ) => void;
  useDeeplink: boolean;
}

interface DocumentUploadState {
  file: Blob | null;
  dataUri: string | null;
  expiryDate: string | null;
  uploadState: DocumentUploadStep;
  needToReuploadFile: boolean;
  submitting: boolean;
  deeplinkFailed: boolean;
  deeplinkTriggered: boolean;
  deeplinkTimeoutId: NodeJS.Timeout | null;
}

type Props = DocumentUploadProps & DocumentUploadComponentConnectedProps;

function getFieldsToDisplayInModalAndErrorsRelatedToThoseFields(
  props: DocumentUploadProps
): [DriverRegistrationPortalNoAuthService.Field[], FieldValidationError[]] {
  const fieldsToDisplayInModal: DriverRegistrationPortalNoAuthService.Field[] =
    [];
  const modalErrors: FieldValidationError[] = [];
  for (const field of props.step.fields) {
    if (field.show_if) {
      const dependentFieldNames = Object.keys(field.show_if);
      for (const dependentFieldName of dependentFieldNames) {
        if (dependentFieldName === props.field.name) {
          const fieldShowIf = field.show_if[
            props.field.name
          ] as DriverRegistrationPortalNoAuthService.DocumentUploadCondition;
          if (
            fieldShowIf.document_type_key === props.field.document_type_key &&
            fieldShowIf.upload_status ===
              DriverRegistrationPortalNoAuthService.UploadStatus.IN_MODAL_DIALOG
          ) {
            fieldsToDisplayInModal.push(field);
            const fieldError = props.fieldErrors?.find(
              (x) => x.property === field.name
            );
            if (fieldError) {
              modalErrors.push(fieldError);
            }
          }
        }
      }
    }
  }
  return [fieldsToDisplayInModal, modalErrors];
}

class DocumentUploadComponent extends React.Component<
  Props,
  DocumentUploadState
> {
  static loadFile(file: File, callback: (value: string) => void): void {
    const fileReader = new FileReader();

    fileReader.onload = (event) => {
      if (event.target && event.target.result) {
        callback(event.target.result as string);
      }
    };

    fileReader.readAsDataURL(file);
  }

  private readonly hiddenFileInput: React.RefObject<HTMLInputElement>;

  private readonly hiddenCameraCapture: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);

    this.hiddenFileInput = React.createRef<HTMLInputElement>();
    this.hiddenCameraCapture = React.createRef<HTMLInputElement>();

    this.showFileUploadDialog = this.showFileUploadDialog.bind(this);
    this.openCameraCapture = this.openCameraCapture.bind(this);
    this.closeModalWindow = this.closeModalWindow.bind(this);
    this.clearDocumentField = this.clearDocumentField.bind(this);
    this.isExampleOpened = this.isExampleOpened.bind(this);
    this.isPreviewOpened = this.isPreviewOpened.bind(this);
    this.isVerificationOpened = this.isVerificationOpened.bind(this);
    this.changePicture = this.changePicture.bind(this);
    this.changePictureWithExample = this.changePictureWithExample.bind(this);
    this.onPreviewButtonClick = this.onPreviewButtonClick.bind(this);
    this.onPreviewFinished = this.onPreviewFinished.bind(this);
    this.onDocumentConfirm = this.onDocumentConfirm.bind(this);
    this.onExpiryDateChange = this.onExpiryDateChange.bind(this);
    this.showFileExample = this.showFileExample.bind(this);
    this.isFileExampleEnabled = this.isFileExampleEnabled.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);

    this.state = {
      file: null,
      dataUri: null,
      expiryDate: null,
      uploadState: DocumentUploadStep.INITIAL,
      needToReuploadFile: false,
      submitting: false,
      deeplinkFailed: false,
      deeplinkTriggered: false,
      deeplinkTimeoutId: null,
    };
  }

  async handleFileUpload(): Promise<void> {
    if (this.state.deeplinkTriggered) {
      return;
    }

    if (this.state.deeplinkTimeoutId) {
      clearTimeout(this.state.deeplinkTimeoutId);
    }
    const timeoutId = setTimeout(() => {
      this.setState({ deeplinkTriggered: false });
      this.props.dispatch(clearErrors());
    }, 1000);
    this.setState({
      deeplinkTriggered: true,
      deeplinkTimeoutId: timeoutId,
    });

    if (this.props.useDeeplink && !this.state.deeplinkFailed) {
      const request = {
        hash: this.props.hash,
        document_type_key: this.props.field.document_type_key ?? '',
      };

      try {
        const result = await partnerDriverApiClient.triggerDocumentUpload(
          request
        );
        if (!result || !result.flow_run_uuid) {
          this.showFileUploadDialog();
          return;
        }

        const deeplink = `${getDeeplinkScheme()}identity-verification/start?flow_run_id=${
          result.flow_run_uuid
        }`;
        window.location.replace(deeplink);
      } catch (e) {
        // Requesting a flow run UUID failed with an unhandled error, attempt to fallback to native upload
        this.setState({ deeplinkFailed: true, deeplinkTriggered: false });
        this.showFileUploadDialog();
      }
    } else {
      // This is not a document supported by SDK capture or previous attempt failed, use native upload
      this.showFileUploadDialog();
    }
  }

  onSubmit(submitting: boolean): void {
    this.setState({ submitting });
  }

  async onDocumentConfirm(expiryDate?: string): Promise<void> {
    if (!this.state.file || !this.props.field.document_type_key) {
      return;
    }

    if (this.state.needToReuploadFile) {
      this.setState({ submitting: true });
      const uploadFile = {
        [this.props.field.document_type_key]: this.state.file,
      };
      try {
        const uploadResponse = await this.props.dispatch<any>(
          fallbackDocumentUploadForEnhancedCapture(expiryDate, uploadFile)
        );
        if (uploadResponse) {
          this.props.dispatch(clearErrors());
          this.props.updateHandler({
            document_id: uploadResponse.document_id,
            document_name: uploadResponse.document_name,
            document_expires: uploadResponse.document_expires,
          });

          this.setState({
            needToReuploadFile: false,
            expiryDate: unixToDateString(uploadResponse.document_expires),
          });

          this.closeModalWindow();
        }
      } catch (e) {
        Logger.log('Error while uploading document', (e as Error).message);
      }
      this.setState({ submitting: false });
    } else {
      this.closeModalWindow();
    }
  }

  onExpiryDateChange(expiryDate: string | null): void {
    this.setState({
      expiryDate,
      needToReuploadFile: true,
    });
  }

  onFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.files) {
      return;
    }

    const file = event.target.files[0];

    DocumentUploadComponent.loadFile(file, (value) => {
      this.deleteDocument().then(() => {
        this.setState({
          file,
          dataUri: value,
          uploadState: DocumentUploadStep.PREVIEW,
          needToReuploadFile: true,
        });
      });
    });
  };

  async onPreviewFinished(): Promise<void> {
    if (!this.hasDocumentFields()) {
      await this.onDocumentConfirm();
      return;
    }
    this.setState({
      uploadState: DocumentUploadStep.INITIAL,
    });

    await new Promise((res) => setTimeout(res, 0));

    this.setState({ uploadState: DocumentUploadStep.VERIFICATION });
  }

  onPreviewButtonClick(): void {
    this.setState({
      uploadState: DocumentUploadStep.PREVIEW,
    });
  }

  getDocumentContentType(): DocumentContentType {
    if (String(this.props.field.document_type_key).includes('photo')) {
      return DocumentContentType.PHOTO;
    }
    return DocumentContentType.DOCUMENT;
  }

  backToPreview = async (): Promise<void> => {
    this.setState({
      uploadState: DocumentUploadStep.INITIAL,
    });

    await new Promise((res) => setTimeout(res, 0));

    this.setState({ uploadState: DocumentUploadStep.PREVIEW });
  };

  deleteDocument = async (): Promise<void> => {
    const currentValue = this.props.field
      .current_value as FleetRegistrationNoAuthService.FleetUploadedDocument | null;
    if (currentValue && currentValue.document_id) {
      return this.props
        .dispatch<any>(
          deleteDocument(this.props.hash, currentValue.document_id)
        )
        .then(() => {
          this.setState({
            file: null,
            dataUri: null,
            expiryDate: null,
          });

          this.props.updateHandler({
            document_id: null as any,
            document_name: null as any,
            document_expires: null,
          });
        });
    }
    return Promise.resolve();
  };

  hasDocumentFields = (): boolean => {
    return !!this.props.field.is_expiry_required;
  };

  async clearDocumentField(): Promise<void> {
    if (this.hiddenFileInput.current) {
      this.hiddenFileInput.current.value = '';
    }
    if (this.hiddenCameraCapture.current) {
      this.hiddenCameraCapture.current.value = '';
    }

    return this.deleteDocument();
  }

  isExampleOpened(): boolean {
    return this.state.uploadState === DocumentUploadStep.EXAMPLE;
  }

  isPreviewOpened(): boolean {
    return this.state.uploadState === DocumentUploadStep.PREVIEW;
  }

  isVerificationOpened(): boolean {
    return this.state.uploadState === DocumentUploadStep.VERIFICATION;
  }

  showFileExample(): void {
    this.setState({
      uploadState: DocumentUploadStep.EXAMPLE,
    });
  }

  showFileUploadDialog(): void {
    if (this.hiddenFileInput.current) {
      this.hiddenFileInput.current.click();
    }
  }

  openCameraCapture(): void {
    if (this.hiddenCameraCapture.current) {
      this.hiddenCameraCapture.current.click();
    }
  }

  closeModalWindow(): void {
    this.props.dispatch(clearErrors());
    if (this.hiddenFileInput.current) {
      this.hiddenFileInput.current.value = '';
    }
    if (this.hiddenCameraCapture.current) {
      this.hiddenCameraCapture.current.value = '';
    }
    this.setState({
      uploadState: DocumentUploadStep.INITIAL,
      deeplinkFailed: false,
    });
  }

  async changePicture(): Promise<void> {
    this.closeModalWindow();
    this.showFileUploadDialog();
  }

  async changePictureWithExample(): Promise<void> {
    this.closeModalWindow();
    this.showFileExample();
    this.showFileUploadDialog();
  }

  isFileExampleEnabled(): boolean {
    if (!this.props.field.document_type_example_url) {
      return false;
    }
    // Currently supported only on mobile screens
    return DeviceData.isMobileScreen();
  }

  render(): React.ReactElement {
    const translations = this.props.field.translations;

    const [fieldsToDisplayInModal, modalErrors] =
      getFieldsToDisplayInModalAndErrorsRelatedToThoseFields(this.props);

    const isStoredOnField = this.props.field.current_value?.document_name;
    const isManuallyUploaded =
      isStoredOnField && !this.props.field.document_status;
    const isUploaded = Boolean(
      isManuallyUploaded ||
        (this.props.field.document_status &&
          UPLOADED_STATUSES.includes(this.props.field.document_status))
    );
    const canReupload =
      this.props.field.document_status &&
      CAN_REUPLOAD_STATUSES.includes(this.props.field.document_status);
    const hasErrors = Boolean(this.props.error || modalErrors.length > 0);

    const showDescription =
      !isUploaded ||
      this.props.field.document_status ===
        DriverRegistrationPortalNoAuthService.DocumentResultStatus.DECLINED;

    const showOptionalLabel = !isUploaded && !this.props.field.is_required;

    const loading = this.state.deeplinkTriggered;

    return (
      <div className="w-full gap-4">
        <div className="flex flex-col w-full pb-6 border-b border-gray-300 rounded overflow-hidden gap-2">
          <div className="flex flex-row justify-between">
            <h3
              id={translations.label}
              data-test="title"
              className="inline text-left rtl:text-right text-xl font-bold leading-6"
            >
              {translations.label}
            </h3>
          </div>
          {!isManuallyUploaded && this.props.field.document_status && (
            <DocumentStatusLabel
              status={this.props.field.document_status}
              error={this.props.field.document_error}
            />
          )}
          {showOptionalLabel && <DocumentOptionalLabel />}
          {showDescription && (
            <div
              data-test="description"
              className="block text-left rtl:text-right text-sm text-gray-700"
            >
              <MarkdownWrapper>{translations.description}</MarkdownWrapper>
            </div>
          )}
          {hasErrors && (
            <div className="mb-2">
              <ErrorHint error={this.props.error} />
              {modalErrors.length > 0 && (
                <ErrorHint
                  error={
                    this.props.commonTranslations.document_upload
                      .check_upload_modal
                  }
                />
              )}
            </div>
          )}
          {isManuallyUploaded && (
            <DocumentStatusLabel
              status={
                DriverRegistrationPortalNoAuthService.DocumentResultStatus
                  .IN_REVIEW
              }
            />
          )}
          {(!isUploaded || canReupload) && (
            <div className="flex rtl:float-right -mt-4 -mb-4">
              <Button
                onClick={this.handleFileUpload}
                color="bg-gray-200"
                loadingTextColor="text-gray-500"
                textColor={loading ? 'text-gray-200' : 'text-gray-900'}
                label={
                  this.props.commonTranslations.document_upload
                    .upload_document_button_label
                }
                disabled={loading}
                loading={loading}
                data-test="upload_button"
                id={`upload_button_${this.props.field.document_type_key}`}
              />
            </div>
          )}
          <input
            type="file"
            data-test="upload_input"
            ref={this.hiddenFileInput}
            className="hidden"
            accept="image/*,.pdf"
            onChange={this.onFileChange}
          />
          <input
            type="file"
            data-test="camera_capture_input"
            ref={this.hiddenCameraCapture}
            className="hidden"
            accept="image/*"
            onChange={this.onFileChange}
            capture
          />
          {this.state.deeplinkFailed && (
            <div
              data-test="upload_failed_try_again"
              className="block text-left rtl:text-right text-sm text-orange-500 mt-6"
            >
              <MarkdownWrapper>
                {
                  this.props.commonTranslations.document_upload
                    .upload_failed_try_again
                }
              </MarkdownWrapper>
            </div>
          )}
        </div>
        {this.isExampleOpened() && (
          <DocumentExample
            isOpen={this.isExampleOpened()}
            submitting={this.state.submitting}
            exampleFileUrl={this.props.field.document_type_example_url ?? ''}
            documentContentType={this.getDocumentContentType()}
            commonTranslations={this.props.commonTranslations}
            selectFileHandler={this.showFileUploadDialog}
            useCameraHandler={this.openCameraCapture}
            closeHandler={this.closeModalWindow}
          />
        )}
        {this.isPreviewOpened() &&
          (this.isFileExampleEnabled() ? (
            <DocumentUploadPreviewWithExample
              isOpen={this.isPreviewOpened()}
              submitting={this.state.submitting}
              fileUrl={this.state.dataUri ?? ''}
              exampleFileUrl={this.props.field.document_type_example_url ?? ''}
              documentContentType={this.getDocumentContentType()}
              commonTranslations={this.props.commonTranslations}
              nextHandler={this.onPreviewFinished}
              changePictureHandler={this.changePictureWithExample}
              closeHandler={this.closeModalWindow}
            />
          ) : (
            <DocumentUploadPreview
              isOpen={this.isPreviewOpened()}
              submitting={this.state.submitting}
              fileUrl={this.state.dataUri ?? ''}
              commonTranslations={this.props.commonTranslations}
              nextHandler={this.onPreviewFinished}
              changePictureHandler={this.changePicture}
              closeHandler={this.closeModalWindow}
              imageSubject={
                this.props.field.image_subject ??
                DriverRegistrationPortalNoAuthService.ImageSubject.TEXT_DOCUMENT
              }
            />
          ))}
        {this.isVerificationOpened() && (
          <DocumentUploadVerification
            field={this.props.field}
            submitting={this.state.submitting}
            onSubmit={() => this.onSubmit(this.state.submitting)}
            isOpen={this.isVerificationOpened()}
            fileUrl={this.state.dataUri ?? ''}
            step={this.props.step}
            fields={fieldsToDisplayInModal}
            isExpiryRequired={!!this.props.field.is_expiry_required}
            expiryChangeHandler={this.onExpiryDateChange}
            expiryDate={this.state.expiryDate}
            commonTranslations={this.props.commonTranslations}
            confirmHandler={this.onDocumentConfirm}
            backHandler={this.backToPreview}
            closeHandler={this.closeModalWindow}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState, ownProps: DocumentUploadProps) => ({
  error: getFieldError(
    `${ownProps.field.name}_${ownProps.field.document_type_key}`
  )(state),
  fieldErrors: getFieldErrors(state),
});

export const connector = connect(mapStateToProps);
type DocumentUploadComponentConnectedProps = ConnectedProps<typeof connector>;
export const EnhancedDocumentUpload = connector(DocumentUploadComponent);
