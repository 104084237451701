import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { useSelector } from '../../redux/store';
import { getTranslations } from '../../redux/form/formSelectors';
import {
  confirmVerification,
  startVerification,
} from '../../redux/verification/verificationThunk';
import {
  getAvailableChannels,
  getCodeLength,
  getCurrentOtpValue,
  getPhone,
  getResendWaitTime,
  isConfirmationLoading,
  isConflictResolutionDisplayed,
  isVerificationLoading,
} from '../../redux/verification/verificationSelectors';
import { useCountdown } from '../../hooks/useCountdown';
import { Loader } from '../common/Loader';
import { MemoizedOtpInput } from './OtpInput';
import { MemoizedLinkElement } from './LinkElement';
import closeImage from '../../assets/close.svg';
import sendSmsImage from '../../assets/send-sms.svg';
import sendWhatsappImage from '../../assets/send-whatsapp.svg';
import requestCallbackImage from '../../assets/request-callback.svg';
import { ErrorBubble } from './ErrorBubble';

import {
  getFirstCommonError,
  getFirstVerificationErrorObject,
} from '../../redux/notification/notificationsSelectors';
import { VerificationError } from '../../redux/notification/notificationActions';
import { ResponseCodes } from '../../api/ResponseCodes';
import { LoginAndNewSignupLinks } from '../common/LoginAndNewSignupLinks';
import { ConflictResolution } from './ConflictResolution';
import { setCurrentOtpValue } from '../../redux/verification/verificationActions';

export const Verification: React.FC = () => {
  const dispatch = useDispatch();

  const verificationError: VerificationError | null = useSelector(
    getFirstVerificationErrorObject
  );
  const commonError = useSelector(getFirstCommonError);

  const isLoading = useSelector(isVerificationLoading);
  const isUploading = useSelector(isConfirmationLoading);
  const showConflictResolution = useSelector(isConflictResolutionDisplayed);
  const commonTranslations = useSelector(getTranslations);

  const phone = useSelector(getPhone);
  const resendWaitTime = useSelector(getResendWaitTime);
  const codeLength = useSelector(getCodeLength);
  const availableChannels = useSelector(getAvailableChannels);

  const { seconds: countdownSeconds, reset: resetCountdown } =
    useCountdown(resendWaitTime);

  const code = useSelector(getCurrentOtpValue);
  const setCode = useCallback(
    (value: string) => {
      dispatch(setCurrentOtpValue(value));
    },
    [dispatch]
  );

  const [isResent, setIsResent] = useState(false);
  const [showResendChannelsModal, setShowResendChannelsModal] = useState(false);
  const [selectedVerificationChannel, setSelectedVerificationChannel] =
    useState(DriverRegistrationPortalNoAuthService.VerificationCodeChannel.SMS);

  const modalRef = React.createRef<HTMLDivElement>();

  const { token } = useParams<{ token: string }>();

  React.useEffect(() => {
    if (code.length === codeLength) {
      dispatch(confirmVerification(token, code));
    }
  }, [dispatch, token, code, codeLength]);

  React.useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        showResendChannelsModal &&
        modalRef.current &&
        !modalRef.current.contains(e.target as Element)
      ) {
        setShowResendChannelsModal(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [modalRef, showResendChannelsModal]);

  const toggleResendModal = React.useCallback(() => {
    setShowResendChannelsModal((prevState) => !prevState);
  }, [setShowResendChannelsModal]);

  const resendCode = (
    channel: DriverRegistrationPortalNoAuthService.VerificationCodeChannel
  ) => {
    resetCountdown();
    setIsResent(true);
    setShowResendChannelsModal(false);
    setCode('');
    setSelectedVerificationChannel(channel);

    dispatch(startVerification(token, channel));
  };

  const isWhatsAppAvailable = (
    channels: DriverRegistrationPortalNoAuthService.VerificationCodeChannel[]
  ): boolean => {
    return channels.includes(
      DriverRegistrationPortalNoAuthService.VerificationCodeChannel.WHATSAPP
    );
  };

  const timerIsFinished = useMemo<boolean>(() => {
    return countdownSeconds === 0;
  }, [countdownSeconds]);

  const showLoader = useMemo<boolean>(() => {
    return isLoading || isUploading;
  }, [isLoading, isUploading]);

  const showError = useMemo<boolean>(() => {
    return (
      !showLoader &&
      ((!!verificationError && !verificationError.show_otp) || !!commonError)
    );
  }, [commonError, showLoader, verificationError]);

  const showCodeInput = !showLoader && !showError;

  const codeSentText = useMemo<string>(() => {
    if (!isResent) {
      return commonTranslations.verification.code_sent_message;
    }

    return selectedVerificationChannel ===
      DriverRegistrationPortalNoAuthService.VerificationCodeChannel.CALL
      ? commonTranslations.verification.code_resent_by_call
      : commonTranslations.verification.code_resent;
  }, [isResent, selectedVerificationChannel, commonTranslations]);

  const showSignupAndLoginLinks =
    verificationError?.code === ResponseCodes.DUPLICATE_PARTNER_FOUND ||
    verificationError?.code === ResponseCodes.PARTNER_ALREADY_HAS_DRIVER ||
    verificationError?.code ===
      ResponseCodes.DRIVER_PROVISIONAL_REGISTRATION_OTP_NOT_SENT ||
    verificationError?.code === ResponseCodes.MULTIPLE_PARTNERS_FOUND;

  const showResendLink =
    verificationError?.code === ResponseCodes.TRY_AGAIN_LATER ||
    verificationError?.code === ResponseCodes.DAILY_LIMIT_REACHED ||
    verificationError?.code === ResponseCodes.SENDING_SMS_FAILED ||
    !!commonError;

  const forceResend = Boolean(
    verificationError &&
      verificationError?.code !==
        ResponseCodes.DRIVER_PROVISIONAL_REGISTRATION_OTP_NOT_FOUND
  );

  return (
    <div className="flex flex-col gap-6 pt-6">
      {showLoader && (
        <div className="flex flex-row justify-center my-8 h-24">
          <Loader
            data-test="app_form_loader"
            textColor="text-green-500"
            id="document_upload_example_loader"
          />
        </div>
      )}
      {showCodeInput && (
        <div className="flex flex-col items-center gap-y-6 relative">
          <div className="flex flex-col items-center">
            <span className="font-semibold text-gray-900 text-2xl mb-2">
              {commonTranslations.verification.enter_code}
            </span>
            <span className="text-gray-700">{codeSentText}</span>
            <span className="font-semibold text-gray-900">{phone}</span>
          </div>

          <MemoizedOtpInput
            value={code}
            length={codeLength}
            onChange={setCode}
            errorMessage={verificationError?.error}
            forceResend={forceResend}
          />
          <div hidden={timerIsFinished}>
            <span className="text-gray-700">
              {commonTranslations.verification.resend_code_in}
            </span>{' '}
            {countdownSeconds}
          </div>
          {timerIsFinished && (
            <MemoizedLinkElement
              text={commonTranslations.verification.resend_code_sms}
              additionalStyles="font-bold"
              onClick={toggleResendModal}
            />
          )}
        </div>
      )}
      {showError && (
        <ErrorBubble error={verificationError?.error ?? commonError ?? ''} />
      )}
      {showSignupAndLoginLinks && <LoginAndNewSignupLinks showLogin />}
      {showConflictResolution && <ConflictResolution />}
      {showResendLink && (
        <div className="text-center">
          <MemoizedLinkElement
            text={commonTranslations.verification.resend_code_sms}
            additionalStyles="font-bold"
            onClick={toggleResendModal}
          />
        </div>
      )}
      {showResendChannelsModal && (
        <div className="flex fixed inset-0 justify-center items-center z-30 bg-black bg-opacity-50">
          <div className="relative">
            <div
              className="border-0 rounded-lg shadow-lg bg-white relative top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-80"
              ref={modalRef}
            >
              <div className="flex flex-col items-start justify-between px-6 pt-4 pb-2 rounded-t-lg gap-4 tablet:p-6 break-words">
                <button
                  type="button"
                  className="absolute top-4 right-4 text-gray-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center"
                  onClick={toggleResendModal}
                >
                  <img
                    src={closeImage}
                    alt=""
                    className="object-contain"
                    width={14}
                    height={14}
                  />
                </button>
                <div className="flex flex-col gap-0.5 pr-4">
                  <span className="font-semibold text-gray-900 text-2xl mb-2">
                    {commonTranslations.verification.did_not_get_the_code}
                  </span>
                  <span className="text-gray-700">{phone}</span>
                </div>
                <div className="flex flex-col w-full">
                  <button
                    type="button"
                    onClick={() =>
                      resendCode(
                        DriverRegistrationPortalNoAuthService
                          .VerificationCodeChannel.SMS
                      )
                    }
                  >
                    <div className="flex flex-row items-center py-4 gap-6 border-b">
                      <img
                        src={sendSmsImage}
                        alt=""
                        className="object-contain"
                        width={24}
                        height={24}
                      />
                      <div className="text-left">
                        {commonTranslations.verification.resend_code_sms}
                      </div>
                    </div>
                  </button>
                  {isWhatsAppAvailable(availableChannels) && (
                    <button
                      type="button"
                      onClick={() =>
                        resendCode(
                          DriverRegistrationPortalNoAuthService
                            .VerificationCodeChannel.WHATSAPP
                        )
                      }
                    >
                      <div className="flex flex-row items-center py-4 gap-6">
                        <img
                          src={sendWhatsappImage}
                          alt=""
                          className="object-contain"
                          width={24}
                          height={24}
                        />
                        <div className="text-left">
                          {commonTranslations.verification.resend_code_whatsapp}
                        </div>
                      </div>
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={() =>
                      resendCode(
                        DriverRegistrationPortalNoAuthService
                          .VerificationCodeChannel.CALL
                      )
                    }
                  >
                    <div className="flex flex-row items-center py-4 gap-6">
                      <img
                        src={requestCallbackImage}
                        alt=""
                        className="object-contain"
                        width={24}
                        height={24}
                      />
                      <div className="text-left">
                        {commonTranslations.verification.resend_code_call}
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
