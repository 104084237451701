import { FleetRegistrationNoAuthService } from '@bolteu/bolt-server-api-driver-portal';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';

const GENERIC_CONDITION_TYPES = [
  DriverRegistrationPortalNoAuthService.ConditionType.GENERIC_CHECKBOX,
  DriverRegistrationPortalNoAuthService.ConditionType.GENERIC_DROPDOWN,
];

const getNumericOperationByType = (
  operation: DriverRegistrationPortalNoAuthService.NumericConditionOperationType
) => {
  switch (operation) {
    case DriverRegistrationPortalNoAuthService.NumericConditionOperationType
      .EQUAL:
      return (a: number, b: number): boolean => a === b;
    case DriverRegistrationPortalNoAuthService.NumericConditionOperationType
      .NOT_EQUAL:
      return (a: number, b: number): boolean => a !== b;
    case DriverRegistrationPortalNoAuthService.NumericConditionOperationType
      .LESS:
      return (a: number, b: number): boolean => a < b;
    case DriverRegistrationPortalNoAuthService.NumericConditionOperationType
      .LESS_OR_EQUAL:
      return (a: number, b: number): boolean => a <= b;
    case DriverRegistrationPortalNoAuthService.NumericConditionOperationType
      .MORE:
      return (a: number, b: number): boolean => a > b;
    case DriverRegistrationPortalNoAuthService.NumericConditionOperationType
      .MORE_OR_EQUAL:
      return (a: number, b: number): boolean => a >= b;
    default:
      return null;
  }
};

export function checkShowField(
  field: DriverRegistrationPortalNoAuthService.Field,
  steps: DriverRegistrationPortalNoAuthService.Step[]
): boolean {
  if (field.show_if) {
    const dependentFieldNames = Object.keys(field.show_if);
    const flattenFields = (
      [] as DriverRegistrationPortalNoAuthService.Field[]
    ).concat(...steps.map((x) => x.fields));

    for (let i = 0; i < dependentFieldNames.length; i += 1) {
      const dependentFieldName = dependentFieldNames[i];
      const condition = field.show_if[dependentFieldName];

      const otherField = flattenFields.find((f) => {
        if (
          condition.type ===
          DriverRegistrationPortalNoAuthService.ConditionType.DOCUMENT_UPLOAD
        ) {
          return (
            (
              condition as DriverRegistrationPortalNoAuthService.DocumentUploadCondition
            ).document_type_key === f.document_type_key
          );
        }
        if (GENERIC_CONDITION_TYPES.includes(condition.type)) {
          return (
            (
              condition as DriverRegistrationPortalNoAuthService.GenericCheckboxCondition
            ).generic_field_key === f.generic_field_key
          );
        }
        return f.name === dependentFieldName;
      });

      if (otherField) {
        if (
          condition?.type ===
          DriverRegistrationPortalNoAuthService.ConditionType.DOCUMENT_UPLOAD
        ) {
          const fieldShowIf = field.show_if[
            dependentFieldName
          ] as DriverRegistrationPortalNoAuthService.DocumentUploadCondition;
          const dependentFieldCurrentValue =
            otherField.current_value as FleetRegistrationNoAuthService.FleetUploadedDocument;
          if (
            (fieldShowIf.upload_status ===
              DriverRegistrationPortalNoAuthService.UploadStatus.NOT_UPLOADED &&
              !!dependentFieldCurrentValue?.document_id) ||
            (fieldShowIf.upload_status !==
              DriverRegistrationPortalNoAuthService.UploadStatus.NOT_UPLOADED &&
              !dependentFieldCurrentValue?.document_id)
          ) {
            return false;
          }
        }

        if (
          condition?.type ===
          DriverRegistrationPortalNoAuthService.ConditionType.GENERIC_CHECKBOX
        ) {
          const fieldShowIf =
            condition as DriverRegistrationPortalNoAuthService.GenericCheckboxCondition;
          if (
            otherField &&
            fieldShowIf &&
            otherField.current_value !== fieldShowIf.equals
          ) {
            return false;
          }
        }

        if (
          condition?.type ===
          DriverRegistrationPortalNoAuthService.ConditionType.CHECKBOX
        ) {
          const fieldShowIf = field.show_if[
            dependentFieldName
          ] as DriverRegistrationPortalNoAuthService.CheckboxCondition;
          if (
            otherField &&
            field.show_if &&
            otherField.current_value !== fieldShowIf.equals
          ) {
            return false;
          }
        }

        if (
          condition?.type ===
          DriverRegistrationPortalNoAuthService.ConditionType.SELECT
        ) {
          const fieldShowIf =
            condition as DriverRegistrationPortalNoAuthService.SelectCondition;
          if (
            otherField &&
            field.show_if &&
            otherField.current_value !== fieldShowIf.equals
          ) {
            return false;
          }
        }

        if (
          condition?.type ===
          DriverRegistrationPortalNoAuthService.ConditionType.NUMERIC
        ) {
          const fieldShowIf =
            condition as DriverRegistrationPortalNoAuthService.NumericCondition;

          const comparator = getNumericOperationByType(fieldShowIf.operation);

          if (
            comparator &&
            otherField &&
            field.show_if &&
            !comparator(
              Number(otherField.current_value),
              Number(fieldShowIf.value)
            )
          ) {
            return false;
          }
        }

        if (
          condition?.type ===
          DriverRegistrationPortalNoAuthService.ConditionType.DEFAULT
        ) {
          const fieldShowIf =
            condition as DriverRegistrationPortalNoAuthService.DefaultCondition;

          return fieldShowIf.empty
            ? !otherField.current_value
            : !!otherField.current_value;
        }
      } else {
        // The field that this field is dependent on does not exist.
        // This flowset is set up incorrectly, however this field should not be rendered.
        return false;
      }
    }
  }
  return true;
}
