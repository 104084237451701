import * as React from 'react';
import { useSelector } from '../../redux/store';
import { getFieldError } from '../../redux/notification/notificationsSelectors';
import ErrorHint from '../common/ErrorHint';
import { BaseInputProps } from './BaseInput';
import FieldDescription from '../common/FieldDescription';
import { FieldLabel } from './FieldLabel';

interface TextboxProps extends BaseInputProps<string | number> {
  type?: 'text' | 'date' | 'email' | 'tel' | 'number';
  placeholder?: string;
  fieldName: string;
  autoComplete?: string;
  isRequired: boolean;
}

export const Textbox: React.FC<TextboxProps> = ({
  type,
  title,
  placeholder,
  description,
  value,
  fieldName,
  updateHandler,
  blurHandler,
  autoComplete,
  isRequired,
}: TextboxProps) => {
  const error = useSelector(getFieldError(fieldName));

  const borderColorClassName = error
    ? 'border-red-500'
    : 'border-gray-200 focus:border-green-500';

  const bgClassName = error ? 'bg-white' : 'bg-gray-200';

  return (
    <div className="w-full">
      <FieldLabel text={title} isRequired={isRequired} />
      <div className="w-full my-2">
        <input
          id={title}
          data-test={`input_${title}`}
          className={`outline-none h-12 p-3 border-2 placeholder-gray-700 rounded focus:bg-white ${borderColorClassName} ${bgClassName} w-full`}
          type={type}
          placeholder={placeholder}
          autoComplete={autoComplete}
          defaultValue={value === null ? '' : value}
          onChange={(e) => {
            updateHandler((e.target as HTMLInputElement).value);
          }}
          onBlur={(e) => {
            blurHandler(e);
          }}
        />
      </div>
      <ErrorHint error={error} />
      <FieldDescription description={description} />
    </div>
  );
};

Textbox.defaultProps = {
  type: 'text',
  placeholder: '',
  autoComplete: undefined,
};
