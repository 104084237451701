import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { Logo } from './Logo';
import LanguageSelector from './LanguageSelector';

export const Header: React.FC = (): ReactElement => {
  const location = useLocation();
  const isRiderUserSignup = location.pathname.startsWith('/rider-user-signup');
  return (
    <div className="flex justify-center items-center h-14">
      <Logo />
      {!isRiderUserSignup && <LanguageSelector />}
    </div>
  );
};
