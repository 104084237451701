import * as React from 'react';
import { useRef } from 'react';
import { useSelector } from '../redux/store';
import { getCommonErrors } from '../redux/notification/notificationsSelectors';
import { CommonError } from '../redux/notification/notificationActions';

const NotificationsSection = ({ items }: NotificationsSectionProps) => {
  const divRef = useRef<HTMLDivElement>(null);

  return (
    <div
      id="notification-container"
      className="text-red-500 justify-center content-center pb-4"
      ref={divRef}
    >
      {items.map((item) => (
        <p className="text-center" key={item.error}>
          {item.error}
        </p>
      ))}
    </div>
  );
};

export const NotificationsArea: React.FC = () => {
  const items = useSelector(getCommonErrors);

  return <>{items.length ? <NotificationsSection items={items} /> : <></>}</>;
};

interface NotificationsSectionProps {
  items: CommonError[];
}
