import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getLoginViaPhoneLink } from '../../common/helpers';
import { useSelector } from '../../redux/store';
import { getTranslations } from '../../redux/form/formSelectors';
import { createAccount } from '../../redux/verification/verificationThunk';

export const ConflictResolution = (): ReactElement => {
  const dispatch = useDispatch();
  const { token } = useParams<{ token: string }>();

  const loginLink = getLoginViaPhoneLink();
  const t = useSelector(getTranslations);

  const handleLoginClick = React.useCallback(() => {
    window.location.replace(loginLink);
  }, [loginLink]);

  const handleSignupClick = React.useCallback(() => {
    dispatch(createAccount(token, true));
  }, [token, dispatch]);

  return (
    <div className="flex flex-col text-center gap-4">
      <div>
        <button
          type="button"
          onClick={handleSignupClick}
          className="text-green-500 font-bold"
        >
          {t.verification.continue_new_registration}
        </button>
      </div>
      <div>
        <button
          type="button"
          onClick={handleLoginClick}
          className="text-green-500 "
        >
          {t.verification.login_to_existing_registration}
        </button>
      </div>
    </div>
  );
};
