import { replace } from 'connected-react-router';
import { Dispatch } from 'react';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { getDriverLoginLink } from '../common/helpers';
import {
  requireManualConflictResolution,
  setCaptchaNeeded,
  setConfirmationLoading,
  setCurrentOtpValue,
} from '../redux/verification/verificationActions';
import { getTranslations } from '../redux/form/formSelectors';
import { getCodeMissingTranslation } from '../redux/localization/localizationSelectors';
import {
  clearErrors,
  commonErrorAdded,
  fieldErrorsAdded,
  FieldValidationError,
  verificationErrorAdded,
} from '../redux/notification/notificationActions';
import { IAppState } from '../redux/types';
import { ResponseCodes } from './ResponseCodes';

export interface CommonResponse {
  code: number;
  message: string;
  text?: string;
  error_data?: CommonErrorData;
}

export interface CommonErrorData {
  errors: FieldValidationError[];
  text: string;
  data?: unknown;
}

export interface CaptchaChallengeResponse extends CommonResponse {
  error_data?: CaptchaChallengeResponseErrorData;
}

export interface CaptchaChallengeResponseErrorData extends CommonErrorData {
  data: CaptchaChallengeResponseData;
}

export interface CaptchaChallengeResponseData {
  challenge: {
    type: DriverRegistrationPortalNoAuthService.CaptchaChallengeType;
    parameters: {
      site_key: string;
    };
  };
}
export interface ValidationError {
  name: 'ValidationError';
  validatorResults: unknown[];
}

export interface ServerError {
  response: CommonResponse;
}

export const isErrorResponse = (error: unknown): error is ServerError => {
  return (
    !!error &&
    (error as ServerError).response !== undefined &&
    ((error as ServerError).response as CommonResponse).code !== undefined &&
    ((error as ServerError).response as CommonResponse).message !== undefined
  );
};

export const isValidationError = (error: unknown): error is ValidationError => {
  return !!error && (error as ValidationError).name === 'ValidationError';
};

export interface FieldsValidationFailedResponse extends CommonResponse {
  error_hint?: string;
}

export const handleErrorResponse = (
  error: unknown,
  dispatch: Dispatch<unknown>,
  unknownErrorTranslation?: string
): void => {
  dispatch(clearErrors());
  if (
    isErrorResponse(error) &&
    error.response?.code !== ResponseCodes.SUCCESS &&
    error.response?.error_data?.text
  ) {
    dispatch(
      commonErrorAdded({
        error: error.response.error_data.text,
      })
    );
  } else if (
    isErrorResponse(error) &&
    error.response?.code !== ResponseCodes.SUCCESS &&
    error.response?.message
  ) {
    dispatch(
      commonErrorAdded({
        error: error.response.message,
        code: error.response.code,
      })
    );
  } else {
    dispatch(
      commonErrorAdded({ error: unknownErrorTranslation ?? 'Unknown error' })
    );
  }
};

export const handleAutosaveFieldValidationErrors = (
  error: unknown,
  fieldName: string,
  dispatch: Dispatch<unknown>
): void => {
  if (
    isErrorResponse(error) &&
    error.response.code ===
      ResponseCodes.DRIVER_REGISTRATION_FORM_VALIDATION_FAILED
  ) {
    const response = error.response as FieldsValidationFailedResponse;
    const fieldValidationError = response.error_data?.errors.find(
      (e) => e.property === fieldName
    );
    if (fieldValidationError) {
      dispatch(fieldErrorsAdded([fieldValidationError]));
    }
  } else {
    // silently ignore
  }
};

export const handleFieldValidationErrors = (
  error: unknown,
  dispatch: Dispatch<unknown>
): void => {
  dispatch(clearErrors());
  if (
    isErrorResponse(error) &&
    error.response.code ===
      ResponseCodes.DRIVER_REGISTRATION_FORM_VALIDATION_FAILED
  ) {
    const response = error.response as FieldsValidationFailedResponse;
    if (response.error_data?.errors) {
      dispatch(fieldErrorsAdded(response.error_data?.errors));
    } else if (response.error_hint) {
      dispatch(
        commonErrorAdded({
          error: response.error_hint,
          code: response.code,
        })
      );
    }
  } else {
    handleErrorResponse(error, dispatch);
  }
};

export const handleVerificationErrorResponse = (
  error: unknown,
  dispatch: Dispatch<unknown>,
  getState: () => IAppState
): void => {
  dispatch(clearErrors());
  const unknownErrorTranslation = getTranslations(
    getState()
  ).common_unknown_error;

  if (isErrorResponse(error) && error.response.code) {
    switch (error.response.code) {
      case ResponseCodes.REQUIRED_BOT_CHALLENGE: {
        const errorData = (error.response as CaptchaChallengeResponse)
          .error_data;
        if (errorData && errorData.data) {
          dispatch(
            setCaptchaNeeded({
              site_key: errorData.data.challenge.parameters.site_key,
              type: errorData.data.challenge.type,
            })
          );
        }
        break;
      }
      case ResponseCodes.DRIVER_PROVISIONAL_REGISTRATION_NOT_FOUND: {
        dispatch(setConfirmationLoading());
        dispatch(replace(`/driver/404`));
        break;
      }
      case ResponseCodes.DRIVER_PROVISIONAL_REGISTRATION_ALREADY_VERIFIED: {
        dispatch(setConfirmationLoading());
        window.location.replace(getDriverLoginLink());
        break;
      }
      case ResponseCodes.DRIVER_PROVISIONAL_REGISTRATION_OTP_NOT_FOUND: {
        dispatch(setCurrentOtpValue(''));
        dispatch(
          verificationErrorAdded({
            error: error.response.error_data?.text ?? error.response.message,
            code: error.response.code,
            show_otp: true,
          })
        );
        break;
      }
      case ResponseCodes.DRIVER_PROVISIONAL_REGISTRATION_OTP_NOT_FOUND_MAX_ATTEMPTS:
      case ResponseCodes.DRIVER_PROVISIONAL_REGISTRATION_OTP_NOT_SENT:
      case ResponseCodes.DRIVER_PROVISIONAL_REGISTRATION_OTP_EXPIRED: {
        dispatch(
          verificationErrorAdded({
            error: error.response.error_data?.text ?? error.response.message,
            code: error.response.code,
            show_otp: true,
          })
        );
        break;
      }
      case ResponseCodes.DUPLICATE_PARTNER_FOUND_WITH_PROMPT: {
        dispatch(requireManualConflictResolution());
        dispatch(
          verificationErrorAdded({
            error:
              error.response.error_data?.text ??
              error.response.message ??
              unknownErrorTranslation,
            code: error.response.code,
          })
        );
        break;
      }
      case ResponseCodes.MULTIPLE_PARTNERS_FOUND: {
        dispatch(
          verificationErrorAdded({
            error:
              error.response.error_data?.text ??
              error.response.message ??
              unknownErrorTranslation,
            code: error.response.code,
          })
        );
        break;
      }
      default:
        dispatch(
          verificationErrorAdded({
            error:
              error.response.error_data?.text ??
              error.response.message ??
              unknownErrorTranslation,
            code: error.response.code,
          })
        );
    }
  } else if (isValidationError(error)) {
    const codeMissingText = getCodeMissingTranslation(getState());

    dispatch(
      verificationErrorAdded({
        error: codeMissingText,
        code: ResponseCodes.INVALID_REQUEST,
      })
    );
  } else {
    dispatch(
      commonErrorAdded({ error: unknownErrorTranslation ?? 'Unknown error' })
    );
  }
};
