import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { FleetRegistrationNoAuthService } from '@bolteu/bolt-server-api-driver-portal';
import { setFormFieldValue } from '../redux/form/formActions';
import { useSelector } from '../redux/store';
import {
  getSteps,
  getTranslations,
  isFormUploading,
} from '../redux/form/formSelectors';
import { Field } from './Field';
import { Button } from './Button';
import { loadExperiment } from '../redux/experiment/experimentThunk';
import { NotificationsArea } from './NotificationsArea';
import { checkShowField } from '../common/fieldConditions';
import { getLanguage } from '../redux/localization/localizationSelectors';
import { getExperimentParameters } from '../redux/experiment/experimentSelectors';
import { validateDocumentsResubmission } from '../redux/form/formThunk';

const DOCUMENTS_RESUBMIT_OPTIMISATION =
  'driver_registration_documents_resubmit';

export interface DocumentResubmitProps {
  hash: string;
}

export const DocumentsResubmit: React.FC<DocumentResubmitProps> = ({
  hash,
}) => {
  const dispatch = useDispatch();
  const commonTranslations = useSelector(getTranslations);
  const steps: DriverRegistrationPortalNoAuthService.Step[] =
    useSelector(getSteps);
  const isUploading = useSelector(isFormUploading);
  const language = useSelector(getLanguage);

  const experimentParameters = useSelector(
    getExperimentParameters(DOCUMENTS_RESUBMIT_OPTIMISATION)
  );

  const [reuploadedDocuments, setReuploadedDocuments] = React.useState(
    new Set()
  );

  useEffect(() => {
    if (hash && language) {
      dispatch(loadExperiment(DOCUMENTS_RESUBMIT_OPTIMISATION, hash, language));
    }
  }, [dispatch, hash, language]);

  const virtualStep = steps[0];

  const documentUploaded = React.useCallback(
    (
      document: DriverRegistrationPortalNoAuthService.Field,
      value: { changed: FleetRegistrationNoAuthService.FleetUploadedDocument }
    ) => {
      setReuploadedDocuments(
        new Set(reuploadedDocuments.add(document.document_type_key))
      );
      dispatch(setFormFieldValue(virtualStep, document, value.changed));
    },
    [dispatch, reuploadedDocuments, virtualStep]
  );

  const documentsToResubmit = React.useMemo(() => {
    if (experimentParameters && experimentParameters.enabled) {
      return virtualStep.fields
        .filter(
          (f) =>
            f.type ===
              DriverRegistrationPortalNoAuthService.FrontendFieldDefinitionType
                .DOCUMENT_UPLOAD &&
            f.current_value &&
            (f.current_value.document_id === null ||
              reuploadedDocuments.has(f.document_type_key))
        )
        .sort((a) => (a.is_required ? -1 : 1));
    }
    return virtualStep.fields.filter(
      (f) =>
        f.type ===
          DriverRegistrationPortalNoAuthService.FrontendFieldDefinitionType
            .DOCUMENT_UPLOAD && f.current_value
    );
  }, [experimentParameters, reuploadedDocuments, virtualStep.fields]);

  return (
    <div className="pt-6">
      <div className="text-center">
        <h1 className="text-2xl font-bold">
          {commonTranslations.document_resubmission.document_resubmission_title}
        </h1>
        {commonTranslations.document_resubmission
          .document_resubmission_subtitle && (
          <div className="mb-8">
            {
              commonTranslations.document_resubmission
                .document_resubmission_subtitle
            }
          </div>
        )}
        <NotificationsArea />
      </div>
      <div className="flex flex-col content-center justify-center text-center mx-auto">
        {documentsToResubmit.map((document) => {
          const showDocument = checkShowField(document, steps);

          return showDocument ? (
            <div
              className="mb-4 flex flex-row content-center items-center justify-center"
              key={document.document_type_key}
            >
              <Field
                fieldDefinition={document}
                commonTranslations={commonTranslations}
                step={virtualStep}
                hash={hash}
                updateHandler={(v) => {
                  documentUploaded(document, v);
                }}
                blurHandler={() => {
                  // Do nothing
                }}
              />
            </div>
          ) : null;
        })}
        <div>
          <Button
            onClick={() => dispatch(validateDocumentsResubmission)}
            color={isUploading ? 'bg-green-200' : 'bg-green-500'}
            loadingTextColor="text-green-500"
            label={isUploading ? '' : 'Submit'}
            disabled={isUploading}
            loading={isUploading}
            id="submit"
          />
        </div>
      </div>
    </div>
  );
};
