import * as React from 'react';
import { BaseProps } from './BaseInput';

interface LinkButtonProps extends BaseProps {
  placeholder: string;
}

export const LinkButton: React.FC<LinkButtonProps> = (props) => {
  return (
    <a href={props.placeholder}>
      <button
        type="button"
        className="w-full tablet:w-auto m-0 mt-6 px-8 py-3 border-none rounded-full text-center font-bold bg-green-500 text-white"
      >
        {props.title}
      </button>
    </a>
  );
};
