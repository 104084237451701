import { Action } from 'redux';

export enum ActionType {
  SET_PENDING_STATUS = 'AUTOSAVE/SET_PENDING_STATUS',
  SET_SAVING_STATUS = 'AUTOSAVE/SET_SAVING_STATUS',
  SET_SUCCESSFUL_STATUS = 'AUTOSAVE/SET_SUCCESSFUL_STATUS',
  SET_FAILED_STATUS = 'AUTOSAVE/SET_FAILED_STATUS',
  SET_FEATURE_ENABLED = 'AUTOSAVE/SET_FEATURE_ENABLED',
}

export interface SetPendingStatus extends Action {
  type: ActionType.SET_PENDING_STATUS;
  timeout: NodeJS.Timeout | null;
  fieldName: string;
  fieldValue: any;
  genericFieldKey: string | null;
}

export const autosavePending = (
  fieldName: string,
  genericFieldKey: string | null,
  fieldValue: any,
  timeout: NodeJS.Timeout
): SetPendingStatus => ({
  type: ActionType.SET_PENDING_STATUS,
  timeout,
  fieldName,
  genericFieldKey,
  fieldValue,
});

export const autosaveEnabled = (value: boolean): SetAutosaveEnabled => ({
  type: ActionType.SET_FEATURE_ENABLED,
  featureEnabled: value,
});

export interface SetSavingStatus extends Action {
  type: ActionType.SET_SAVING_STATUS;
}

export const autosaveSaving = (): SetSavingStatus => ({
  type: ActionType.SET_SAVING_STATUS,
});

export interface SetSuccessfulStatus extends Action {
  type: ActionType.SET_SUCCESSFUL_STATUS;
}

export const autosaveSuccessful = (): SetSuccessfulStatus => ({
  type: ActionType.SET_SUCCESSFUL_STATUS,
});

export interface SetFailedStatus extends Action {
  type: ActionType.SET_FAILED_STATUS;
}

export interface SetAutosaveEnabled extends Action {
  type: ActionType.SET_FEATURE_ENABLED;
  featureEnabled: boolean;
}

export const autosaveFailed = (): SetFailedStatus => ({
  type: ActionType.SET_FAILED_STATUS,
});
