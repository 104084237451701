import React from 'react';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { DocumentModal } from './DocumentModal';
import { PicturePreview } from './PicturePreview';
import closeImage from '../../../assets/close.svg';
import backImage from '../../../assets/back-black.svg';
import { Button } from '../../Button';
import { NotificationsArea } from '../../NotificationsArea';
import { PreviewCheckpoint } from './PreviewCheckpoint';
import { useSelector } from '../../../redux/store';
import { getCommonErrors } from '../../../redux/notification/notificationsSelectors';

interface DocumentUploadPreviewProps {
  isOpen: boolean;
  submitting: boolean;
  fileUrl: string;
  commonTranslations: DriverRegistrationPortalNoAuthService.CommonTranslations;
  nextHandler: () => void;
  changePictureHandler: () => void;
  closeHandler: () => void;
  imageSubject: DriverRegistrationPortalNoAuthService.ImageSubject;
}

export const DocumentUploadPreview: React.FC<DocumentUploadPreviewProps> = ({
  isOpen,
  submitting,
  fileUrl,
  commonTranslations,
  nextHandler,
  changePictureHandler,
  closeHandler,
  imageSubject,
}: DocumentUploadPreviewProps) => {
  const commonPreviewTranslations = commonTranslations.document_upload.preview;
  const errors = useSelector(getCommonErrors);

  let checklist: JSX.Element[] = [];
  switch (imageSubject) {
    case DriverRegistrationPortalNoAuthService.ImageSubject.FACE_CLOSEUP: {
      checklist = [
        <PreviewCheckpoint
          text={commonPreviewTranslations.face_closeup.point_1.description}
        />,
        <PreviewCheckpoint
          text={commonPreviewTranslations.face_closeup.point_2.description}
        />,
        <PreviewCheckpoint
          text={commonPreviewTranslations.face_closeup.point_3.description}
        />,
      ];
      break;
    }
    case DriverRegistrationPortalNoAuthService.ImageSubject.OTHER_PHOTOGRAPH: {
      checklist = [
        <PreviewCheckpoint
          text={commonPreviewTranslations.other_photograph.point_1.description}
        />,
        <PreviewCheckpoint
          text={commonPreviewTranslations.other_photograph.point_2.description}
        />,
      ];
      break;
    }
    case DriverRegistrationPortalNoAuthService.ImageSubject.TEXT_DOCUMENT:
    default: {
      checklist = [
        <PreviewCheckpoint
          text={commonPreviewTranslations.text_document.point_1.description}
        />,
        <PreviewCheckpoint
          text={commonPreviewTranslations.text_document.point_2.description}
        />,
        <PreviewCheckpoint
          text={commonPreviewTranslations.text_document.point_3.description}
        />,
      ];
      break;
    }
  }

  const viewportHeight = window.innerHeight;

  return (
    <DocumentModal isOpen={isOpen} onClose={closeHandler}>
      <div className="flex flex-col tablet:p-6" data-test="upload_preview">
        <button
          className="hidden tablet:inline tablet:self-end"
          type="button"
          onClick={closeHandler}
        >
          <img
            src={closeImage}
            data-test="close_button_image"
            alt="close dialog"
            className="object-contain"
          />
        </button>
        <div className="bg-white tablet:hidden">
          <button onClick={closeHandler} type="button">
            <img
              src={backImage}
              data-test="back_button_image"
              alt="close dialog"
              className="object-contain p-3"
            />
          </button>
        </div>
        <div className="block tablet:flex tablet:flex-row tablet:justify-between">
          {viewportHeight < 700 ? (
            <div className="h-[380px] w-full bg-white tablet:w-[312px] mr-3 rtl:ml-3">
              <PicturePreview fileUrl={fileUrl} fullHeight />
            </div>
          ) : (
            <div className="h-[425px] w-full bg-white tablet:w-[312px] mr-3 rtl:ml-3">
              <PicturePreview fileUrl={fileUrl} fullHeight />
            </div>
          )}
          <div className="flex flex-col rounded-t-lg relative bg-white tablet:bg-transparent tablet:rounded-none tablet:static tablet:w-[312px] mt-4">
            <h3
              data-test="title"
              className="mt-1 tablet:mt-0 text-center text-xl font-bold mb-3"
            >
              {commonPreviewTranslations.confirm_title}
            </h3>
            <div>{checklist}</div>
          </div>
        </div>
        {!!errors.length && (
          <div className="pt-6 px-6">
            <NotificationsArea />
          </div>
        )}
        <div className="block px-6 pb-4 tablet:flex tablet:flex-row tablet:justify-around tablet:flex-row-reverse">
          <Button
            id="confirm_photo"
            onClick={nextHandler}
            color={submitting ? 'bg-green-500' : 'bg-green-500'}
            loadingTextColor="text-green-500"
            label={
              submitting ? '' : commonPreviewTranslations.confirm_button_label
            }
            disabled={submitting}
            loading={submitting}
          />
          <Button
            id="choose_photo"
            onClick={changePictureHandler}
            color="bg-gray-200"
            loadingTextColor="text-gray-500"
            textColor={submitting ? 'text-gray-500' : 'text-gray-900'}
            label={commonPreviewTranslations.change_photo_button_label}
            disabled={submitting}
            loading={false}
          />
        </div>
      </div>
    </DocumentModal>
  );
};
