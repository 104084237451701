import * as React from 'react';
import ReactSelect from 'react-select';
import ErrorHint from '../common/ErrorHint';
import { BaseInputProps } from './BaseInput';
import FieldDescription from '../common/FieldDescription';
import { Choice, customStyles, errorStyles, isChoice } from './SelectCommon';
import { FieldLabel } from './FieldLabel';

interface SelectProps extends BaseInputProps<string | number> {
  choices: Choice[];
  hideLabel?: boolean;
  placeholder?: string;
  isSearchable?: boolean;
  isRequired: boolean;
}

export const Select: React.FC<SelectProps> = ({
  choices,
  title,
  description,
  value,
  updateHandler,
  blurHandler,
  error,
  hideLabel,
  placeholder,
  isSearchable,
  isRequired,
}: SelectProps) => {
  const selectedValue =
    choices.find((choise) => choise.value === value) || null;

  return (
    <div className="w-full">
      {!hideLabel && <FieldLabel text={title} isRequired={isRequired} />}
      <div className="w-full my-2">
        <ReactSelect
          id={title}
          name={title}
          placeholder={placeholder || ''}
          value={selectedValue}
          options={choices}
          getOptionLabel={(item: Choice) => item.title?.toString() ?? ''}
          getOptionValue={(item: Choice) => item.value?.toString() ?? ''}
          styles={error ? errorStyles : customStyles}
          isSearchable={isSearchable}
          isClearable
          // blurInputOnSelect (triggers autosave prematurely)
          closeMenuOnSelect
          onChange={(option) => {
            const newVal = isChoice(option) ? option.value : null;
            updateHandler(newVal);
          }}
          onBlur={(e) => {
            blurHandler(e);
          }}
        />
      </div>
      <ErrorHint error={error} />
      <FieldDescription description={description} />
    </div>
  );
};

Select.defaultProps = {
  hideLabel: false,
  placeholder: '',
  isSearchable: false,
};
