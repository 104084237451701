import * as React from 'react';
import ReactSelect, { MultiValue, SingleValue } from 'react-select';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { FieldLabel } from './FieldLabel';
import ErrorHint from '../common/ErrorHint';
import FieldDescription from '../common/FieldDescription';
import { useSelector } from '../../redux/store';
import { getFieldError } from '../../redux/notification/notificationsSelectors';
import { Choice, customStyles, errorStyles } from './SelectCommon';
import driverRegistrationApiClient from '../../api/DriverRegistrationApi';
import { getTranslations } from '../../redux/form/formSelectors';

interface CarModelIdProps {
  field: DriverRegistrationPortalNoAuthService.Field;
  updateHandler: (
    changed: DriverRegistrationPortalNoAuthService.Field['current_value']
  ) => void;
  blurHandler: (
    changed: DriverRegistrationPortalNoAuthService.Field['current_value']
  ) => void;
}

enum UserSelectedVehicleType {
  ALL_VEHICLES,
  CARS_ONLY,
  MOTORBIKES_ONLY,
}

const isChoice = (v: unknown): v is Choice => {
  return !!v && (v as Choice).value !== undefined;
};

export function CarModelId({
  field,
  updateHandler,
  blurHandler,
}: CarModelIdProps): React.ReactElement {
  const commonTranslations = useSelector(getTranslations);
  const error = useSelector(getFieldError(field.name));
  const { label, description } = field.translations;
  const mode =
    field.vehicle_type_mode ??
    DriverRegistrationPortalNoAuthService.VehicleTypeModeSelection.HIDDEN;
  const showVehicleTypeDropdown =
    mode !==
    DriverRegistrationPortalNoAuthService.VehicleTypeModeSelection.HIDDEN;
  const disableModeSelect = [
    DriverRegistrationPortalNoAuthService.VehicleTypeModeSelection.CARS_ONLY,
    DriverRegistrationPortalNoAuthService.VehicleTypeModeSelection
      .MOTORBIKES_ONLY,
  ].includes(mode);

  const VehicleTypeOptions = React.useMemo(
    () => [
      {
        title: commonTranslations.vehicle_types.all,
        value: UserSelectedVehicleType.ALL_VEHICLES,
      },
      {
        title: commonTranslations.vehicle_types.cars,
        value: UserSelectedVehicleType.CARS_ONLY,
      },
      {
        title: commonTranslations.vehicle_types.motorbikes,
        value: UserSelectedVehicleType.MOTORBIKES_ONLY,
      },
    ],
    [commonTranslations]
  );

  const [vehicleType, setVehicleType] = React.useState<Choice | null>(
    !showVehicleTypeDropdown ? VehicleTypeOptions[0] : null
  );
  const [manufacturer, setManufacturer] = React.useState<Choice | null>(null);
  const [model, setModel] = React.useState<Choice | null>(null);

  const [manufacturerOptions, setManufacturerOptions] = React.useState<
    Choice[]
  >([]);
  const [modelOptions, setModelOptions] = React.useState<Choice[]>([]);

  const showManufacturerDropDown = React.useMemo(() => {
    if (!vehicleType) {
      return false;
    }
    return true;
  }, [vehicleType]);

  const showModelSelectDropDown = React.useMemo(() => {
    if (!manufacturer) {
      return false;
    }
    return true;
  }, [manufacturer]);

  // Set vehicle type based on mode when first rendered
  React.useEffect(() => {
    if (
      [
        DriverRegistrationPortalNoAuthService.VehicleTypeModeSelection
          .CARS_ONLY,
        DriverRegistrationPortalNoAuthService.VehicleTypeModeSelection
          .MOTORBIKES_ONLY,
      ].includes(mode)
    ) {
      setVehicleType(
        mode ===
          DriverRegistrationPortalNoAuthService.VehicleTypeModeSelection
            .CARS_ONLY
          ? VehicleTypeOptions[1]
          : VehicleTypeOptions[2]
      );
    } else {
      setVehicleType(VehicleTypeOptions[0]);
    }
  }, [mode, VehicleTypeOptions]);

  // Load manufacturer options when vehicle type changes
  React.useEffect(() => {
    if (!vehicleType) {
      setManufacturerOptions([]);
      return;
    }

    const fetchData = async (
      payload: DriverRegistrationPortalNoAuthService.GetVehicleMakesRequest
    ) => {
      const response = await driverRegistrationApiClient.getVehicleMakes(
        payload
      );
      setManufacturerOptions(response.list);
    };

    const payload: DriverRegistrationPortalNoAuthService.GetVehicleMakesRequest =
      {};
    if (vehicleType.value === UserSelectedVehicleType.CARS_ONLY) {
      payload.vehicle_type =
        DriverRegistrationPortalNoAuthService.CarModelVehicleType.CAR;
    } else if (vehicleType.value === UserSelectedVehicleType.MOTORBIKES_ONLY) {
      payload.vehicle_type =
        DriverRegistrationPortalNoAuthService.CarModelVehicleType.MOTORBIKE;
    }

    fetchData(payload);
  }, [vehicleType]);

  // Load model options when manufacturer changes
  React.useEffect(() => {
    if (!manufacturer) {
      setModelOptions([]);
      return;
    }

    const fetchData = async (
      payload: DriverRegistrationPortalNoAuthService.GetVehicleModelsRequest
    ) => {
      const response =
        await driverRegistrationApiClient.getVehicleModelsByManufacturer(
          payload
        );
      setModelOptions(response.list);
    };

    let payloadVehicleType:
      | DriverRegistrationPortalNoAuthService.CarModelVehicleType
      | undefined;

    switch (vehicleType?.value) {
      case UserSelectedVehicleType.CARS_ONLY:
        payloadVehicleType =
          DriverRegistrationPortalNoAuthService.CarModelVehicleType.CAR;
        break;
      case UserSelectedVehicleType.MOTORBIKES_ONLY:
        payloadVehicleType =
          DriverRegistrationPortalNoAuthService.CarModelVehicleType.MOTORBIKE;
        break;
      default:
      // No-op, leave as undefined
    }

    const payload: DriverRegistrationPortalNoAuthService.GetVehicleModelsRequest =
      {
        manufacturerId: manufacturer.value as number,
        vehicle_type: payloadVehicleType,
      };
    fetchData(payload);
  }, [manufacturer, vehicleType]);

  const handleVehicleTypeChange = React.useCallback(
    (option: MultiValue<Choice> | SingleValue<Choice>) => {
      if (!isChoice(option)) {
        return;
      }
      if (option === vehicleType) {
        return;
      }
      setVehicleType(option);
      setManufacturerOptions([]);
      setManufacturer(null);
    },
    [vehicleType]
  );

  const handleManufacturerChange = React.useCallback(
    (option: MultiValue<Choice> | SingleValue<Choice>) => {
      if (!isChoice(option)) {
        return;
      }
      if (option === manufacturer) {
        return;
      }
      setManufacturer(option);
      setModelOptions([]);
      updateHandler(null);
      setModel(null);
    },
    [manufacturer, updateHandler]
  );

  const handleModelChange = React.useCallback(
    (option: MultiValue<Choice> | SingleValue<Choice>) => {
      if (!isChoice(option)) {
        return;
      }
      if (option === model) {
        return;
      }
      setModel(option);
      updateHandler(option?.value);
    },
    [model, updateHandler]
  );

  const getOptionLabel = React.useCallback(
    (item: Choice) => item.title?.toString() ?? '',
    []
  );
  const getOptionValue = React.useCallback(
    (item: Choice) => item.value?.toString() ?? '',
    []
  );

  return (
    <>
      <FieldLabel text={label} isRequired={field.is_required} />

      {showVehicleTypeDropdown && (
        <div className="w-full my-2">
          <ReactSelect
            id={field.name}
            name={field.name}
            placeholder={
              commonTranslations.vehicle_types.vehicle_type_placeholder
            }
            value={vehicleType}
            options={VehicleTypeOptions}
            isDisabled={disableModeSelect}
            styles={
              error && !showManufacturerDropDown ? errorStyles : customStyles
            }
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            isSearchable
            // blurInputOnSelect (triggers autosave prematurely)
            closeMenuOnSelect
            onChange={handleVehicleTypeChange}
          />
        </div>
      )}

      {showManufacturerDropDown && (
        <div className="w-full my-2">
          <ReactSelect
            id={field.name}
            name={field.name}
            placeholder={
              commonTranslations.vehicle_types.vehicle_manufacturer_placeholder
            }
            value={manufacturer}
            options={manufacturerOptions}
            styles={
              error && !showModelSelectDropDown ? errorStyles : customStyles
            }
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            isSearchable
            // blurInputOnSelect (triggers autosave prematurely)
            closeMenuOnSelect
            onChange={handleManufacturerChange}
          />
        </div>
      )}

      {showModelSelectDropDown && (
        <div className="w-full my-2">
          <ReactSelect
            id={field.name}
            name={field.name}
            placeholder={
              commonTranslations.vehicle_types.vehicle_model_placeholder
            }
            value={model}
            options={modelOptions}
            styles={error ? errorStyles : customStyles}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            isSearchable
            // blurInputOnSelect (triggers autosave prematurely)
            closeMenuOnSelect
            onChange={handleModelChange}
            onBlur={(e) => {
              blurHandler(e);
            }}
          />
        </div>
      )}

      <ErrorHint error={error} />
      <FieldDescription description={description} />
    </>
  );
}
