import * as React from 'react';
import FieldDescription from '../common/FieldDescription';
import { BaseInputProps } from './BaseInput';
import { useSelector } from '../../redux/store';
import { getFieldError } from '../../redux/notification/notificationsSelectors';
import ErrorHint from '../common/ErrorHint';
import MarkdownWrapper from '../common/MarkdownWrapper';

interface CheckboxProps extends BaseInputProps<boolean> {
  fieldName: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  title,
  description,
  value,
  updateHandler,
  blurHandler,
  fieldName,
}: CheckboxProps) => {
  const error = useSelector(getFieldError(fieldName));
  const [isChecked, setIsChecked] = React.useState(value);

  const borderColorClass = isChecked ? 'border-green-500' : 'border-gray-400';
  const bgColorClass = isChecked ? 'bg-green-500' : 'bg-white';

  const borderColorClassName = error ? 'border-red-500' : '';

  return (
    <div className="w-full">
      <div className="flex flex-row items-center justify-start">
        <input
          className={`appearance-none outline-none h-6 w-6 border-2 ${bgColorClass} ${borderColorClass} ${borderColorClassName} rounded pointer-events-auto shrink-0`}
          type="checkbox"
          id={title}
          defaultChecked={!!value}
          onChange={(e) => {
            setIsChecked(!isChecked);
            updateHandler(e.target.checked);
          }}
          onBlur={(e) => {
            blurHandler(e);
          }}
        />
        {isChecked && (
          <svg
            className="h-4 w-4 fill-current text-white absolute pointer-events-none ml-1 rtl:mr-1"
            viewBox="0 0 14 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M4.91667 9.58332C4.66667 9.58332 4.5 9.49998 4.33333 9.33332L1 5.99998C0.666667 5.66665 0.666667 5.16666 1 4.83332C1.33333 4.49999 1.83333 4.49999 2.16667 4.83332L4.91667 7.58333L11.8333 0.666656C12.1667 0.333323 12.6667 0.333323 13 0.666656C13.3333 0.99999 13.3333 1.49998 13 1.83332L5.5 9.33332C5.33333 9.49998 5.16667 9.58332 4.91667 9.58332Z" />
          </svg>
        )}
        <label htmlFor={title} className="ml-2 rtl:mr-2">
          <MarkdownWrapper>{title}</MarkdownWrapper>
        </label>
      </div>
      <ErrorHint error={error} />
      <FieldDescription description={description} />
    </div>
  );
};
