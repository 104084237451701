import * as React from 'react';
import { useSelector } from 'react-redux';
import { getTranslations } from '../redux/form/formSelectors';
import { Button } from './Button';

export const RefreshButton: React.FC = () => {
  const commonTranslations = useSelector(getTranslations);

  return (
    <Button
      onClick={() => window.location.reload()}
      color="bg-green-500"
      label={commonTranslations.common_actions.refresh}
      disabled={false}
      loading={false}
      id="refreshButton"
    />
  );
};
