import {
  FieldValidationError,
  CommonError,
  VerificationError,
} from './notificationActions';
import { IAppState } from '../types';

export const getFieldError =
  (fieldName?: string) =>
  (state: IAppState): string | undefined => {
    if (!fieldName) {
      return undefined;
    }
    const errors = state.notifications?.fieldErrors?.find(
      (x) => x.property === fieldName
    );
    return errors?.error ?? undefined;
  };

export const getFieldErrors = (state: IAppState): FieldValidationError[] =>
  state.notifications?.fieldErrors ?? [];

export const getCommonErrors = (state: IAppState): CommonError[] =>
  state.notifications?.commonErrors ?? [];

export const getFirstCommonError = (state: IAppState): string | null => {
  if (state.notifications.commonErrors.length > 0) {
    return state.notifications.commonErrors[0].error;
  }
  return null;
};

export const getFirstVerificationErrorObject = (
  state: IAppState
): VerificationError | null => {
  if (state.notifications.verificationErrors.length > 0) {
    return state.notifications.verificationErrors[0];
  }
  return null;
};
