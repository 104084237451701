import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { LoginAndNewSignupLinks } from './common/LoginAndNewSignupLinks';
import { setLoadingStatus } from '../redux/auth/authActions';
import { LoadingStatus } from '../redux/types';
import { useSelector } from '../redux/store';
import { getTranslations } from '../redux/form/formSelectors';

export const NotFound = (): ReactElement => {
  const dispatch = useDispatch();
  const t = useSelector(getTranslations);

  dispatch(setLoadingStatus(LoadingStatus.FAILED));

  return (
    <div className="flex flex-col pt-6 gap-6">
      <div className="text-6xl font-bold text-center">404</div>
      <div className="text-center">{t.common_labels.page_not_found}</div>
      <LoginAndNewSignupLinks />
    </div>
  );
};
