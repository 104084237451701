import React, { useMemo, useState } from 'react';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import MarkdownWrapper from '../common/MarkdownWrapper';
import { LoadingIcon } from '../common/LoadingIcon';
import { RefreshButton } from '../RefreshButton';

interface ColoredIconBannerProps {
  data: DriverRegistrationPortalNoAuthService.ColoredIconBanner;
}

export const ColoredIconBanner: React.FC<ColoredIconBannerProps> = ({
  data,
}: ColoredIconBannerProps) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const action = useMemo(() => {
    switch (data.action) {
      case DriverRegistrationPortalNoAuthService.BannerActionType.REFRESH_PAGE:
        return (
          <div className="flex justify-end">
            <RefreshButton />
          </div>
        );
      case DriverRegistrationPortalNoAuthService.BannerActionType.NONE:
      default:
        return null;
    }
  }, [data.action]);

  if (!data.title_html) {
    return null;
  }

  return (
    <div
      style={{ backgroundColor: data.background_color }}
      className="rounded-2xl border p-3"
    >
      <div className="flex flex-row items-center gap-2">
        <div>
          <img
            src={data.icon_url}
            alt="banner_icon"
            width={48}
            height={48}
            style={{
              display: isImageLoaded ? 'block' : 'none',
              maxWidth: 'unset',
            }}
            onLoad={() => setIsImageLoaded(true)}
          />
          {!isImageLoaded && (
            <LoadingIcon className="text-green-500 animate-spin mx-auto" />
          )}
        </div>
        <div className="justify-center flex-col">
          <div className="pb-1 pt-1">
            <MarkdownWrapper>{data.title_html}</MarkdownWrapper>
          </div>
          {data.description_html ? (
            <div className="pb-1">
              <MarkdownWrapper>{data.description_html}</MarkdownWrapper>
            </div>
          ) : null}
        </div>
      </div>
      {action}
    </div>
  );
};
