import React from 'react';
import MarkdownWrapper from '../common/MarkdownWrapper';
import accepted from '../../assets/accepted.png';
import { LoadingIcon } from '../common/LoadingIcon';
import icon from '../../assets/check-mark.svg';
import { hasPartnerLoginDeeplinkSupport } from '../../common/helpers';
import { getTranslations } from '../../redux/form/formSelectors';
import { useSelector } from '../../redux/store';
import { getDeeplinkScheme } from '../../common/environment';

export const AcceptedFinalScreen: React.FC = () => {
  const commonTranslations = useSelector(getTranslations);
  const [showLoginButton, setShowLoginButton] = React.useState(
    hasPartnerLoginDeeplinkSupport()
  );
  const [showLoginHints, setShowLoginHints] = React.useState(
    !hasPartnerLoginDeeplinkSupport()
  );
  const [isRedirectInProgress, setIsRedirectInProgress] = React.useState(false);
  const onLoginButtonClick = React.useCallback(async () => {
    if (isRedirectInProgress) {
      return;
    }
    setIsRedirectInProgress(true);
    window.location.replace(`${getDeeplinkScheme()}partner-login/`);
    const timeoutMs = hasPartnerLoginDeeplinkSupport() ? 10000 : 3000;
    // In case deeplink did not work - display login hints (fallback)
    setTimeout(() => {
      setShowLoginButton(false);
      setShowLoginHints(true);
    }, timeoutMs);
  }, [isRedirectInProgress]);
  return (
    <div className="flex flex-col gap-6 pt-6 text-center">
      <img
        height={148}
        width={200}
        src={accepted}
        alt={commonTranslations.final_screen.driver_account_approved_title}
        className="mx-auto"
      />
      <div className="flex flex-col gap-4">
        <h1 className="text-2xl font-bold">
          <MarkdownWrapper>
            {commonTranslations.final_screen.driver_account_approved_title}
          </MarkdownWrapper>
        </h1>
        <div className="text-gray-700 text-sm">
          <MarkdownWrapper>
            {
              commonTranslations.final_screen
                .driver_account_approved_description
            }
          </MarkdownWrapper>
        </div>
      </div>
      <div className="flex flex-col gap-2 w-full mx-auto max-w-sm">
        {showLoginButton && (
          <button
            type="button"
            className="py-3 border-none rounded-full text-center font-bold bg-green-500 text-white"
            onClick={onLoginButtonClick}
            disabled={isRedirectInProgress}
          >
            {isRedirectInProgress ? (
              <LoadingIcon className="text-white animate-spin mx-auto" />
            ) : (
              commonTranslations.final_screen
                .driver_account_approved_login_as_driver
            )}
          </button>
        )}
        {showLoginHints && (
          <div className="flex flex-row mx-auto items-center gap-2">
            <img
              src={icon}
              alt={commonTranslations.final_screen.install_bolt_app}
              width={24}
              height={24}
              color="#2F313F"
            />
            <div className="text-gray-700 text-sm">
              {commonTranslations.final_screen.install_bolt_app}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
