import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { Button } from '../Button';
import qrBolt from '../../assets/qr-bolt-driver.png';
import qrHopp from '../../assets/qr-hopp-driver.png';
import { DeviceData } from '../../common/deviceData';
import { getTranslations } from '../../redux/form/formSelectors';
import { ButtonLink } from '../ButtonLink';
import {
  getAppBrand,
  getLinkToAppStore,
  getLinkToAppStoreForAutoLogin,
  getLinkToHuaweiStore,
} from '../../common/environment';
import { continueInBrowser } from '../../redux/verification/verificationThunk';
import { getCreateAccountResult } from '../../redux/verification/verificationSelectors';
import { getMixpanel } from '../../redux/mixpanel/mixpanelSelectors';
import { MixpanelUiEvents } from '../../common/events';
import { initAppPromotion } from '../../redux/auth/authThunk';
import { getMagicToken } from '../../redux/auth/authSelectors';
import { loadMixpanel } from '../../redux/mixpanel/mixpanelThunk';

export const AppPromotion: React.FC = () => {
  const dispatch = useDispatch();

  const mixpanel = useSelector(getMixpanel);
  const authMagicToken = useSelector(getMagicToken);
  const commonTranslations = useSelector(getTranslations);
  const createAccountResult = useSelector(getCreateAccountResult);

  const androidIosLink = authMagicToken
    ? getLinkToAppStoreForAutoLogin(authMagicToken)
    : getLinkToAppStore();
  const huaweiLink = getLinkToHuaweiStore();

  const onContinueInBrowser = () => {
    mixpanel?.track(MixpanelUiEvents.CONTINUE_IN_BROWSER_CLICKED);
    dispatch(continueInBrowser());
  };

  const qrCodeSrc = getAppBrand() === 'hopp' ? qrHopp : qrBolt;
  const isRequired =
    createAccountResult?.app_promotion ===
    DriverRegistrationPortalNoAuthService.AppPromotionMode.REQUIRED;

  const onContinueInApp = () => {
    mixpanel?.track(MixpanelUiEvents.CONTINUE_IN_APP_CLICKED, {
      forcedToDownloadApp: `${isRequired}`,
      withAutoLogin: `${!!authMagicToken}`,
    });
  };

  useEffect(() => {
    const loadData = async () => {
      await dispatch(initAppPromotion());
      // relies on hash
      dispatch(loadMixpanel());
    };
    loadData();
  }, [dispatch]);

  return (
    <div>
      <div className="pt-6">
        <div className="text-center mx-auto">
          <h1 className="text-2xl font-bold mb-4">
            {commonTranslations.app_promotion.get_driver_app}
          </h1>
          <div className="text-gray-700 mt-1">
            {commonTranslations.app_promotion.please_download_app_text}
          </div>
          <div className="text-gray-700 mb-4">
            {commonTranslations.app_promotion.you_can_login}
          </div>
          {DeviceData.isMobileBrowser() ? (
            <div className="w-full">
              <ButtonLink
                href={androidIosLink}
                onClick={onContinueInApp}
                target="_blank"
                color="bg-green-500"
                label={
                  DeviceData.isAndroid()
                    ? commonTranslations.app_promotion.download_on_google_play
                    : commonTranslations.app_promotion.download_on_app_store
                }
                id="download_app"
              />
            </div>
          ) : (
            <div className="w-full text-gray-700 mb-4">
              {commonTranslations.app_promotion.scan_qr_code}
              <img
                src={qrCodeSrc}
                alt=""
                width={150}
                height={150}
                className="mx-auto mt-2"
              />
              <br />
              {commonTranslations.app_promotion.or_use_links} &nbsp;
              <a target="_blank" rel="noreferrer" href={androidIosLink}>
                Android
              </a>
              &nbsp; | &nbsp;
              <a target="_blank" rel="noreferrer" href={androidIosLink}>
                iOS
              </a>
              &nbsp; | &nbsp;
              <a target="_blank" rel="noreferrer" href={huaweiLink}>
                Huawei
              </a>
            </div>
          )}
          {!isRequired && (
            <div>
              <Button
                onClick={onContinueInBrowser}
                color="bg-gray-200"
                textColor="text-gray-900"
                label={commonTranslations.app_promotion.continue_in_browser}
                disabled={false}
                loading={false}
                id="continue_in_browser"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
